import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import InputField from "../inputs/InputField";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateMobileProcessorMemoryMutation,
    useUpdateMobileProcessorMemoryMutation
} from "../../views/product/slices/mobileProduct.slice";

const INITIALVALUES = {
    os: "",
    ui: "",
    ram: "",
    rom: "",
    cpu: "",
    gpu: "",
    chipset: "",
    card_slot: ""
};

const MobileProcessorMemoryForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createMobileProcessorMemory] = useCreateMobileProcessorMemoryMutation();
    const [updateMobileProcessorMemory] = useUpdateMobileProcessorMemoryMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateMobileProcessorMemory({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(2);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createMobileProcessorMemory({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(2);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <InputField 
                                name="ram"
                                label="Input mobile RAM information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="rom"
                                label="Input mobile ROM information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="cpu"
                                label="Input mobile CPU information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="gpu"
                                label="Input mobile GPU information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="os"
                                label="Input mobile operating system information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="ui"
                                label="Input mobile UI information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="card_slot"
                                label="Input mobile card slot information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="chipset"
                                label="Input mobile chipset information"
                            />
                        </Col> 

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(0)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default MobileProcessorMemoryForm;