import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout";
import { useQuery } from "../../../utilities/useQuery";
import EnlistStep from "../../../components/steps/EnlistStep";
import MainSection from "../../../components/section/MainSection";
import { useGetLaptopByIdQuery } from "../slices/laptopProduct.slice";
import ProductMoreInfoForm from "../../../components/enlistForm/ProductMoreInfo";
import LaptopProcessorForm from "../../../components/enlistForm/LaptopProcessor";
import LaptopPortNetworkForm from "../../../components/enlistForm/LaptopPort&Network";
import LaptopPhysicalPowerForm from "../../../components/enlistForm/LaptopPhysicalPower";
import LaptopMemoryStorageForm from "../../../components/enlistForm/LaptopMemory&Storage";
import LaptopAudioKeyboardForm from "../../../components/enlistForm/LaptopAudio&Keyboard";
import LaptoGraphicsDisplayForm from "../../../components/enlistForm/LaptopGraphics&display";

const LaptopEnlist = () => {
    const query = useQuery();
    const id = query.get("id");
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const { isLoading,  data} = useGetLaptopByIdQuery(id);

    const STEPS = [
        {
            title: <span onClick={() => activeStep > 0 && setActiveStep(0)}>Processor</span>,
        },
        {
            title: <span onClick={() => activeStep > 1 && setActiveStep(1)}>Memory & Storage</span>,
        },
        {
            title: <span onClick={() => activeStep > 2 && setActiveStep(2)}>Graphics & Display</span>,
        },
        {
            title: <span onClick={() => activeStep > 3 && setActiveStep(3)}>Ports & Network</span>,
        },
        {
            title: <span onClick={() => activeStep > 2 && setActiveStep(2)}>Audio & Keyboard</span>,
        },
        {
            title: <span onClick={() => activeStep > 3 && setActiveStep(3)}>Power & Physical</span>,
        },
        {
            title: <span onClick={() => activeStep > 4 && setActiveStep(4)}>More Info</span>,
        }
    ];
    
    console.log(data);

    return (
        <Layout>
            <section>
                <MainSection
                    title="Laptop Enlist"
                >
                    {!isLoading && data && (
                        <>
                            <EnlistStep 
                                items={STEPS}
                                activeStep={activeStep} 
                                setActiveStep={setActiveStep}
                            />

                            {activeStep === 0 && (
                                <LaptopProcessorForm 
                                    setActiveStep={setActiveStep}
                                    update={data?.laptop_processor}
                                    initialValues={data?.laptop_processor}
                                    handleBack={() => navigate(`/product/update-product/${id}`)}
                                />
                            )}

                            {activeStep === 1 && (
                                <LaptopMemoryStorageForm 
                                    setActiveStep={setActiveStep}
                                    update={data?.laptop_memory_storage}
                                    initialValues={data?.laptop_memory_storage}
                                />
                            )}

                            {activeStep === 2 && (
                                <LaptoGraphicsDisplayForm 
                                    setActiveStep={setActiveStep}
                                    update={data?.laptop_graphics_display}
                                    initialValues={data?.laptop_graphics_display}
                                />
                            )}

                            {activeStep === 3 && (
                                <LaptopPortNetworkForm 
                                    setActiveStep={setActiveStep}
                                    update={data?.laptop_ports_network}
                                    initialValues={data?.laptop_ports_network}
                                />
                            )}

                            {activeStep === 4 && (
                                <LaptopAudioKeyboardForm 
                                    setActiveStep={setActiveStep}
                                    update={data?.laptop_audio_keyboard}
                                    initialValues={data?.laptop_audio_keyboard}
                                />
                            )}

                            {activeStep === 5 && (
                                <LaptopPhysicalPowerForm 
                                    setActiveStep={setActiveStep}
                                    update={data?.laptop_physical_power}
                                    initialValues={data?.laptop_physical_power}
                                />
                            )}

                            {activeStep === 6 && (
                                <ProductMoreInfoForm 
                                    update={data?.product_more_info}
                                    handleBack={() => setActiveStep(5)}
                                    initialValues={data?.product_more_info}
                                />
                            )}
                        </>
                    )}
                </MainSection>
            </section>
        </Layout>
    );
}
 
export default LaptopEnlist;