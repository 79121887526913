import { useRef } from "react";
import { Row, Col } from "antd";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import TextArea from "../../../components/inputs/TextArea";
import ImageCard from "../../../components/card/ImageCard";
import InputField from "../../../components/inputs/InputField";
import { useUploadProductImageMutation } from "../slices/product.slice";
import AddProductPrice from "../../../components/inputs/AddProductPrice";
import BackAndGoForwad from "../../../components/button/BackAndGoForwad";
import ProductImageUpload from "../../../components/inputs/ProductImageUpload";
import ProductBrandSelect from "../../../components/select/ProductBrandSelect";
import ProductCategorySelect from "../../../components/select/ProductCategorySelect";
import {
    StyleDiv,
    StyleImageDiv,
    StyleImageSection,
} from "./styles/ProductForm.style";

const INITIALVALUES = {
    slug: "",
    title: "",
    brand: "",
    prices: [],
    images: [],
    category: "",
    meta_title: "",
    description: "",
    meta_description: "",
};

const ProductForm = ({ update, handleSubmit, initialValues }) => {
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const [uploadProductImage] = useUploadProductImageMutation();

    const uploadImage = async (file, cb) => {
        try {
            const { data, error } = await uploadProductImage(file);

            !error && data && cb(data?.url, { title: "Amanullha zoha" });
        } catch (error) {
            console.log(error);
        }
    };

    const handleProductImage = async (event, setFieldValue, images) => {
        try {
            const file = event.target.files[0];
            const { data, error } = await uploadProductImage(file);

            const payload = {
                image_path: data?.url,
                id: new Date().getMilliseconds(),
            };

            !error && data && setFieldValue("images", [...images, payload]);
        } catch (error) {
            console.log(error);
        }
    };

    console.log(initialValues);

    return (
        <Formik
            // validationSchema={blogSchema}
            onSubmit={(values, action) =>
                handleSubmit(action, {
                    ...values,
                    description: editorRef.current.getContent(),
                })
            }
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit, setFieldValue, values }) => (
                <Form onSubmit={handleSubmit}>
                    {console.log(values, "hi")}
                    <Row gutter={[24, 12]}>
                        {/* <Col xs={24} lg={24}>
                            <StyleDiv>
                                <ProductCategorySelect
                                    category={values.category}
                                    setCategory={(value) =>
                                        setFieldValue("category", value)
                                    }
                                />

                                {values?.category && (
                                    <ProductBrandSelect
                                        brand={values.brand}
                                        setBrand={(value) =>
                                            setFieldValue("brand", value)
                                        }
                                    />
                                )}
                            </StyleDiv>
                        </Col> */}

                        <Col xs={24} lg={12}>
                            <InputField
                                name="title"
                                label="Select Product Category"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField
                                name="title"
                                label="Select Product brand"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField
                                name="title"
                                label="Write product title"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField
                                name="meta_title"
                                label="Write product meta title"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <TextArea
                                name="meta_description"
                                label="Write blog meta description"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <TextArea
                                name="meta_description"
                                label="Write blog meta description"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField
                                name="slug"
                                label="Write product slug"
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <InputField
                                name="affiliate_link"
                                label="Paste your affiliate link"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <AddProductPrice
                                prices={values.prices}
                                handleSetPrics={(value) =>
                                    setFieldValue("prices", value)
                                }
                            />
                        </Col>

                        <Col xs={24}>
                            <Editor
                                initialValue={values?.description}
                                apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                                onInit={(evt, editor) =>
                                    (editorRef.current = editor)
                                }
                                init={{
                                    content_style: "body { background: #fff; }",
                                    color_map: [
                                        "#ffffff",
                                        "White",
                                        "#BEBEBE",
                                        "gray",
                                        "#ffffff",
                                        "White",
                                    ],
                                    height: "500px",
                                    font_size_formats:
                                        "8px 10px 12px 14px 16px 18px 20px 24px 34px 48px 51px",
                                    plugins:
                                        "link image table emoticons code preview importcss tinydrive image autolink visualblocks visualchars fullscreen lists link media template charmap table emoticons directionality ",
                                    toolbar:
                                        "undo redo | fontsizeselect | fontsize | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor emoticons | link image media | code preview",
                                    branding: false,
                                    file_picker_types: "image",
                                    file_picker_callback: function (
                                        cb,
                                        value,
                                        meta,
                                    ) {
                                        const input =
                                            document.createElement("input");

                                        input.setAttribute("type", "file");
                                        input.setAttribute("accept", "image/*");
                                        input.onchange = function () {
                                            const file = this.files[0];
                                            const reader = new FileReader();

                                            reader.onload = function () {
                                                uploadImage(file, cb);
                                            };
                                            reader.readAsDataURL(file);
                                        };

                                        input.click();
                                    },
                                    relative_url: false,
                                    automatic_uploads: true,
                                }}
                            />
                        </Col>

                        <Col xs={24}>
                            <StyleImageSection>
                                <StyleImageDiv>
                                    <ProductImageUpload
                                        name="images"
                                        setFieldValue={setFieldValue}
                                        handleChange={(event) =>
                                            handleProductImage(
                                                event,
                                                setFieldValue,
                                                values.images,
                                            )
                                        }
                                    />
                                </StyleImageDiv>

                                {values?.images?.map((item) => (
                                    <ImageCard
                                        key={item.id}
                                        url={item.image_path}
                                    />
                                ))}
                            </StyleImageSection>
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad
                                handleBack={() => navigate("/product")}
                                title={
                                    update
                                        ? "Update & Go Forward"
                                        : "Create & Go Forward"
                                }
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default ProductForm;
