import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import InputField from "../inputs/InputField";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateLaptopPortsNetworkMutation,
    useUpdateLaptopPortsNetworkMutation
} from "../../views/product/slices/laptopProduct.slice";

const INITIALVALUES = {
    lan: "",
    wifi: "",
    vga_d_sub: "",
    hdmi_port: "",
    bluetooth: "",
    usb_2_port: "",
    usb_3_port: "",
    usb_c_port: "",
    display_port: "",
    mini_dp_port: "",
    optical_drive: "",
    headphone_port: "",
    mini_hdmi_port: "",
    micro_hdmi_port: "",
    microphone_port: "",
    security_lock_slot: "",
    multimedia_card_slot: "",
    supported_multimedia_card: ""
};

const LaptopPortNetworkForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createLaptopPortsNetwork] = useCreateLaptopPortsNetworkMutation();
    const [updateLaptopPortsNetwork] = useUpdateLaptopPortsNetworkMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateLaptopPortsNetwork({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(4);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createLaptopPortsNetwork({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(4);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <InputField 
                                name="lan"
                                label="Input laptop LAN support"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="wifi"
                                label="Input laptop wifi support"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="vga_d_sub"
                                label="Input laptop VGA support"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="bluetooth"
                                label="Input laptop bluetooth support"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="hdmi_port"
                                label="Input laptop HDMI port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="usb_2_port"
                                label="Input laptop USB 2 port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="usb_3_port"
                                label="Input laptop USB 3 port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="usb_c_port"
                                label="Input laptop USB C port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="display_port"
                                label="Input laptop display port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="mini_dp_port"
                                label="Input laptop mini display port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="optical_drive"
                                label="Input laptop optical drive"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="headphone_port"
                                label="Input laptop headphone port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="mini_hdmi_port"
                                label="Input laptop mini HDMI port"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="micro_hdmi_port"
                                label="Input laptop micro HDMI port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="microphone_port"
                                label="Input laptop microphone port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="security_lock_slot"
                                label="Input laptop security lock slot"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="multimedia_card_slot"
                                label="Input laptop multimedia card slot"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="supported_multimedia_card"
                                label="Input laptop supported multimedia card"
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(4)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default LaptopPortNetworkForm;