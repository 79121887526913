import BlogTable from "../components/BlogTable";
import Layout from "../../../components/layout";
import CreateLink from "../components/CreateLink";
import { useNavigate, useLocation } from "react-router-dom";
import MainSection from "../../../components/section/MainSection";
import { useGetAllBlogListQuery } from "../slices/getBlogList.slice";
import GlobalPagination from "../../../components/pagination/GlobalPagination";
import DataNotFound from "../../../components/section/DataNotFound";

const BlogList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const page = searchParams.get("page");

    const { data, isError, isLoading } = useGetAllBlogListQuery(
        page ? page : 1,
    );

    const handlePagination = (pageValue) => {
        navigate(`/blog?page=${pageValue}`);
    };

    return (
        <Layout>
            <MainSection title="All Blog List">
                <CreateLink />

                {data?.blogs?.length === 0 && !isError && !isLoading && (
                    <DataNotFound />
                )}

                {data?.blogs?.length > 0 && !isError && !isLoading && (
                    <>
                        <BlogTable items={data.blogs} />

                        {data.metaData.total > data.metaData.limit && (
                            <GlobalPagination
                                total={data.metaData.total}
                                pageSize={data.metaData.limit}
                                handlePagination={handlePagination}
                                activePage={data.metaData.activePage}
                            />
                        )}
                    </>
                )}
            </MainSection>
        </Layout>
    );
};

export default BlogList;
