import { string, object } from "yup";

const blogSchema = object().shape({
    title: string()
        .required("This field must not be empty."),
    meta_title: string()
        .required("This field must not be empty."),
    slug: string()
        .required("This field must not be empty."),
    // description: string()
    //     .required("This  field must not be empty."),
    meta_desc: string()
        .required("This  field must not be empty."),
    sort_desc: string()
        .required("This  field must not be empty."),
    category: string()
        .required("This  field must not be empty."),
    // banner_image: string()
    //     .required("This  field must not be empty.")
});

export default blogSchema;