import { Icon } from "@iconify/react";
import { useUserLogoutMutation } from "../../views/login/slices/login.slice";
import {
    StyleLi,
    StyleUl,
    StyleNav,
    StyleLink,
    StyleLogoutBtn,
} from "./styles/Navbar.style";

const Navbar = () => {
    const [userLogout] = useUserLogoutMutation();

    const handleLogout = () => userLogout();

    return (
        <StyleNav>
            <StyleUl>
                <StyleLi>
                    <StyleLink to="/">
                        <Icon icon="ri:product-hunt-fill" />

                        <div className="icon-title">
                            <h2>BpLabels</h2>
                        </div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/">
                        <Icon icon="material-symbols:dashboard" />

                        <div className="icon-title">Dashboard</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/user">
                        <Icon icon="solar:user-bold" />

                        <div className="icon-title">User</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/profile">
                        <Icon icon="carbon:user-role" />

                        <div className="icon-title">Profile</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/permission">
                        <Icon icon="icon-park-solid:permissions" />

                        <div className="icon-title">Permission</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/service">
                        <Icon icon="eos-icons:service" />

                        <div className="icon-title">Service</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/product">
                        <Icon icon="eos-icons:products" />

                        <div className="icon-title">Product</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/category">
                        <Icon icon="tabler:category" />

                        <div className="icon-title">Category</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/brand">
                        <Icon icon="simple-icons:brandfolder" />

                        <div className="icon-title">Brand</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/country">
                        <Icon icon="gis:search-country" />

                        <div className="icon-title">Country</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/blog">
                        <Icon icon="carbon:blog" />

                        <div className="icon-title">Blog</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLink to="/contact">
                        <Icon icon="mdi:contact" />

                        <div className="icon-title">Contact</div>
                    </StyleLink>
                </StyleLi>

                <StyleLi>
                    <StyleLogoutBtn type="button" onClick={handleLogout}>
                        <Icon icon="basil:logout-solid" />

                        <div className="icon-title">Logout</div>
                    </StyleLogoutBtn>
                </StyleLi>
            </StyleUl>
        </StyleNav>
    );
};

export default Navbar;
