import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout";
import BrandForm from "../components/BrandForm";
import MainSection from "../../../components/section/MainSection";
import { useCreateBrandMutation } from "../slices/brand.slice";

const CreateBrand = () => {
    const navigate = useNavigate();
    const [createBrand, { isLoading, isError, error, status }] =
        useCreateBrandMutation();

    const handleSubmit = (action, data) => createBrand(data);

    // const handleSubmit = (action, data) => console.log(data);

    if (isError) {
        toast.error(error.data);
    }

    if (status === "fulfilled") {
        toast.success("Successfully brand created.");
        navigate("/brand");
    }

    return (
        <Layout>
            <MainSection title="Create Brand">
                <BrandForm handleSubmit={handleSubmit} />
            </MainSection>
        </Layout>
    );
};

export default CreateBrand;
