import { useField } from "formik";
import { StyleError, StyleLabel, StyleInput } from "./styles/Input.style";

const InputField = ({ type, label, className, placeholder, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <div>
            {label && <StyleLabel htmlFor={props.name}>{label}</StyleLabel>}

            <StyleInput
                {...field}
                className={className}
                placeholder={placeholder}
                type={type ? type : "text"}
            />

            {meta?.error && meta?.touched ? (
                <StyleError>{meta.error}</StyleError>
            ) : null}
        </div>
    );
};

export default InputField;
