import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateCameraMainFeatureMutation,
    useUpdateCameraMainFeatureMutation
} from "../../views/product/slices/cameraProduct.slice";

const INITIALVALUES = {
    live_view: "",
    release_date: "",
    digital_zoom: "",
    product_range: "",
    remote_control: "",
    face_detection: "",
    red_eye_reduction: "",
    compatible_lenses: ""
};

const CameraMainFeatureForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createCameraMainFeature] = useCreateCameraMainFeatureMutation();
    const [updateCameraMainFeature] = useUpdateCameraMainFeatureMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateCameraMainFeature({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(4);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createCameraMainFeature({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(4);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="live_view"
                                label="Input camera live view"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="release_date"
                                label="Input camera release date"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="digital_zoom"
                                label="Input camera digital zoom"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="product_range"
                                label="Input camera product range"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="remote_control"
                                label="Input camera remote control"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="face_detection"
                                label="Input camera face detection"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="red_eye_reduction"
                                label="Input camera red eye reduction"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="compatible_lenses"
                                label="Input camera compatible lenses"
                            />
                        </Col> 

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(2)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default CameraMainFeatureForm;