import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1`,
        credentials: "include",
    }),
    tagTypes: ["profile"],
    endpoints: (builder) => ({
        userLogin: builder.mutation({
            query: (data) => ({
                body: data,
                url: "/public/login",
                method: "POST",
            }),
            invalidatesTags: ["profile"],
        }),
        userLogout: builder.mutation({
            query: () => ({
                body: {},
                url: "/secured/logout",
                method: "POST",
            }),
            invalidatesTags: ["profile"],
        }),
        getProfile: builder.query({
            query: () => "/secured/users/profile",
            providesTags: ["profile"],
        }),
    }),
});

export const {
    useGetProfileQuery,
    useUserLoginMutation,
    useUserLogoutMutation,
} = userApi;
