import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import ProductFaq from "./ProductFaq";
import { toast } from "react-toastify";
import InputField from "../inputs/InputField";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useQuery } from "../../utilities/useQuery";
import { useEffect, useRef, useState } from "react";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useUploadProductImageMutation,
    useCreateProductMoreInfoMutation,
    useUpdateProductMoreInfoMutation
} from "../../views/product/slices/product.slice";

const INITIALVALUES = {
    title: "",
    description: "",
    product_faqs: []
};

const ProductMoreInfoForm = ({
    update,
    handleBack,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [uploadProductImage] = useUploadProductImageMutation();
    const [createProductMoreInfo] = useCreateProductMoreInfoMutation();
    const [updateProductMoreInfo] = useUpdateProductMoreInfoMutation();

    const uploadImage = async (file, cb) => {
        try {
            const { data, error } = await uploadProductImage(file);

            (!error && data) && cb(data?.url, { title: "Amanullha zoha" });
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
            description: editorRef.current.getContent()
        };

        if(update && initialValues) {
            const { data, error } = await updateProductMoreInfo({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                action.resetForm();
                navigate("/product");

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createProductMoreInfo({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            action.resetForm();
            navigate("/product");

            return toast.success("Create successfully");
        }
    }

    useEffect(() => {
        setLoading(false);
    },[]);

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit, values, setFieldValue }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <InputField 
                                name="title"
                                label="Add more information title"
                            />
                        </Col>

                        <ProductFaq 
                            handleSetFaq={setFieldValue}
                            product_faqs={values?.product_faqs}
                        />

                        <Col xs={24}>
                            {!loading && (
                                <Editor
                                    apiKey="gj9ei06xxexf7bhbmdm2o1pnsudmnpn7nmfkzzmc1tjj41ku"
                                    initialValue={values?.description}
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    init={{
                                        content_style: "body { background: #fff; }",
                                        color_map: [
                                            '#ffffff', 'White',
                                            '#BEBEBE', 'gray',
                                            '#ffffff', 'White',
                                        ],
                                        height: '500px',
                                        font_size_formats: '8px 10px 12px 14px 16px 18px 20px 24px 34px 48px 51px',
                                        plugins: 'link image table emoticons code preview importcss tinydrive image autolink visualblocks visualchars fullscreen lists link media template charmap table emoticons directionality ',
                                        toolbar: 'undo redo | fontsizeselect | fontsize | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor emoticons | link image media | code preview',
                                        branding: false,
                                        file_picker_types: 'image',
                                        file_picker_callback: function (cb, value, meta) {
                                            const input = document.createElement("input");
                                            
                                            input.setAttribute("type", "file");
                                            input.setAttribute("accept", "image/*");
                                            input.onchange = function () {
                                                const file = this.files[0];
                                                const reader = new FileReader();

                                                reader.onload = function () {
                                                    uploadImage(file, cb);
                                                };
                                                reader.readAsDataURL(file);
                                            };

                                            input.click();
                                        },
                                        relative_url: false,
                                        automatic_uploads: true
                                    }}
                                />
                            )}
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={handleBack}
                                title={update ? "Update & Go Product List" : "Create & Go Go Product List"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default ProductMoreInfoForm;