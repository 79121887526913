import { 
    StyleDiv,
    StyleTitle,
    StyleCount,
    StyleInfoCard 
} from "./styles/InfoCard.style";

const InfoCard = ({
    icon,
    count,
    title
}) => {
    return (
        <StyleInfoCard>
            <StyleDiv>
                <StyleCount>
                    {count}
                </StyleCount>

                <StyleTitle>
                    {title}
                </StyleTitle>
            </StyleDiv>

            {icon}
        </StyleInfoCard>
    );
}
 
export default InfoCard;