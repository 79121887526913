import { useRef } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import {
    StyleContainer,
    StyleMainSection,
    StyleContentSection
} from "./styles/Layout.style";

const Layout = ({ children }) => {
    const ref = useRef();

    const handleToggleMenu = () => {
        if (ref.current.classList.contains("active")) {
            return ref.current.classList.remove("active");
        }

        ref.current.classList.add("active");
    }

    return (
        <StyleContainer>
            <Navbar />

            <StyleMainSection ref={ref}>
                <Header 
                    handleToggleMenu={handleToggleMenu}
                />
                
                <StyleContentSection>
                    {children}
                </StyleContentSection>
            </StyleMainSection>
        </StyleContainer>
    );
}
 
export default Layout;