import { Pagination } from "antd";
import styled from "styled-components";

const StyleDiv = styled.div`
    display: flex;
    margin-top: 12px;
    justify-content: center;
`;

const StylePagination = styled(Pagination)`
    .ant-pagination-prev, .ant-pagination-next{
        color: #000000;
        font-size: 13px;
        font-weight: 600;
        background: #fff;
        border: 1px solid #F1F1F1;
    }

    .ant-pagination-item{
        color: #333333;
        font-size: 13px;
        font-weight: 600;
        background: #fff;
        border: 1px solid #F1F1F1;

        &.ant-pagination-item-active{
            background: #2F80ED;
    
            a{
                color: #fff;
            }
    
            &:hover{
                a{
                    color: #fff;
                }
            }
        }
    }
`;

export {
    StyleDiv,
    StylePagination
};