import { Icon } from "@iconify/react";
import { useState, useRef, useEffect } from "react";
import {
    StyleDiv,
    StyleOption,
    StyleSelectDiv,
} from "./styles/CountrySelect.style";

const CATEGORY = [
    {
        id: 1,
        value: "bangladesh",
    },
    {
        id: 2,
        value: "india",
    },
    {
        id: 3,
        value: "pakistan",
    },
    {
        id: 4,
        value: "sri lanka",
    },
];

const CountrySelect = ({ country, setCountry }) => {
    const ref = useRef();
    const [openList, setOpenList] = useState(false);

    const handleSelect = (item) => {
        setOpenList(false);
        setCountry(item.value);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpenList(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <StyleSelectDiv>
            <StyleDiv ref={ref} open={openList}>
                <button type="button" onClick={() => setOpenList(true)}>
                    {country ? country : "Select Country"}

                    <Icon
                        icon="iconamoon:arrow-up-2-light"
                        rotate={3}
                        vFlip={true}
                    />
                </button>

                {openList && (
                    <StyleOption>
                        {CATEGORY.map((item) => (
                            <li
                                key={item.id}
                                onClick={() => handleSelect(item)}
                                className={
                                    country === item.value ? "active" : " "
                                }
                            >
                                {item.value}
                            </li>
                        ))}
                    </StyleOption>
                )}
            </StyleDiv>
        </StyleSelectDiv>
    );
};

export default CountrySelect;
