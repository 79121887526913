import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const productApi = createApi({
    reducerPath: "productApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/product`,
        credentials: "include",
    }),
    tagTypes: ["products", "product"],
    endpoints: (builder) => ({
        getAllProductList: builder.query({
            query: (page = 1) => `?page=${page}`,
            providesTags: ["products"],
        }),
        getProductById: builder.query({
            query: (id) => `/${id}`,
            providesTags: ["product"],
        }),
        createProduct: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["products", "product"],
        }),
        updateProduct: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["products", "product"],
        }),
        createProductMoreInfo: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/more-info",
            }),
            invalidatesTags: ["products", "product"],
        }),
        updateProductMoreInfo: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                method: "PUT",
                url: `/more-info/${id}`,
            }),
            invalidatesTags: ["products", "product"],
        }),
        createProductFeature: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/more-info",
            }),
            invalidatesTags: ["products", "product"],
        }),
        updateProductFeature: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                method: "PUT",
                url: `/more-info/${id}`,
            }),
            invalidatesTags: ["products", "product"],
        }),
        uploadProductImage: builder.mutation({
            query: (data) => {
                const formDataBody = new FormData();
                formDataBody.append("image", data);

                return {
                    url: "/images",
                    method: "POST",
                    body: formDataBody,
                };
            },
        }),
        productActive: builder.mutation({
            query: ({ id }) => ({
                data: {},
                method: "PUT",
                url: `/active/${id}`,
            }),
            invalidatesTags: ["products", "product"],
        }),
        productDeactive: builder.mutation({
            query: ({ id }) => ({
                data: {},
                method: "PUT",
                url: `/deactive/${id}`,
            }),
            invalidatesTags: ["products", "product"],
        }),
    }),
});

export const {
    useGetProductByIdQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useProductActiveMutation,
    useGetAllProductListQuery,
    useProductDeactiveMutation,
    useUploadProductImageMutation,
    useCreateProductFeatureMutation,
    useUpdateProductFeatureMutation,
    useCreateProductMoreInfoMutation,
    useUpdateProductMoreInfoMutation,
} = productApi;
