import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateLaptopPhysicalPowerMutation,
    useUpdateLaptopPhysicalPowerMutation
} from "../../views/product/slices/laptopProduct.slice";

const INITIALVALUES = {
    color: "",
    weight: "",
    others: "",
    battery: "",
    warranty: "",
    dimensions: "",
    backup_time: "",
    battery_type: "",
    adapter_type: "",
    power_adapter: "",
    body_material: "",
    device_lighting: "",
    warranty_details: "",
    battery_capacity: "",
    warranty_claim_duration: ""
};

const LaptopPhysicalPowerForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createLaptopPhysicalPower] = useCreateLaptopPhysicalPowerMutation();
    const [updateLaptopPhysicalPower] = useUpdateLaptopPhysicalPowerMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateLaptopPhysicalPower({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(6);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createLaptopPhysicalPower({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(6);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="color"
                                label="Input laptop color"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="weight"
                                label="Input laptop weight"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="others"
                                label="Input laptop others information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery"
                                label="Input laptop battery infromation"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="warranty"
                                label="Input laptop warranty information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="dimensions"
                                label="Input laptop dimensions"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="backup_time"
                                label="Input laptop backup time"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery_type"
                                label="Input laptop battery type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="adapter_type"
                                label="Input laptop adapter type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="power_adapter"
                                label="Input laptop power adapter"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="body_material"
                                label="Input laptop body material"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="device_lighting"
                                label="Input laptop device lighting"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery_capacity"
                                label="Input laptop battery capacity"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="warranty_details"
                                label="Input laptop warranty details"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="warranty_claim_duration"
                                label="Input laptop warranty claim duration"
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(4)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default LaptopPhysicalPowerForm;