import { StyleLabel, StyleInput } from "./styles/Input.style";

const CountryMaxPriceInput = ({
    type,
    name,
    label,
    error,
    value,
    onChange,
    className,
    placeholder,
    ...props
}) => {
    return (
        <div>
            {label && <StyleLabel htmlFor={props.name}>{label}</StyleLabel>}

            <StyleInput
                {...props}
                name={name}
                value={value}
                onChange={onChange}
                className={className}
                placeholder={placeholder}
                type={type ? type : "text"}
            />

            {error && <p>{error[name]}</p>}
        </div>
    );
};

export default CountryMaxPriceInput;
