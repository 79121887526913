import {
    StyleButtonDiv,
    StyleSubmitButton
} from "./styles/SubmitButton.style";

const SubmitButton = ({
    title
}) => {
    return (
        <StyleButtonDiv>
            <StyleSubmitButton type="submit">
                {title}
            </StyleSubmitButton>
        </StyleButtonDiv>
    );
}
 
export default SubmitButton;