import { Col, Row } from "antd";
import ProductTable from "../../../components/table/ProuctTable";
import { 
    StyleLink,
    StyleHeader,
    StyleHeading,
    StyleContentSection,
    StyleOverViewSection 
} from "./styles/OverviewSection.style";

const OverviewSection = () => {
    return (
        <StyleOverViewSection>
            <Row gutter={[24, 24]}>
                <Col md={24} lg={16}>
                    <StyleContentSection>
                        <StyleHeader>
                            <StyleHeading>
                                Recent Products
                            </StyleHeading>

                            <StyleLink to="/">
                                See More
                            </StyleLink>
                        </StyleHeader>

                        <ProductTable />
                    </StyleContentSection>
                </Col>

                <Col md={24} lg={8}>
                    <StyleContentSection>
                        <StyleHeader>
                            <StyleHeading>
                                Recent Users
                            </StyleHeading>

                            <StyleLink to="/">
                                See More
                            </StyleLink>
                        </StyleHeader>


                    </StyleContentSection>
                </Col>
            </Row>
        </StyleOverViewSection>
    );
}
 
export default OverviewSection;