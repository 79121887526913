import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateCameraImageVideoDisplayMutation,
    useUpdateCameraImageVideoDisplayMutation
} from "../../views/product/slices/cameraProduct.slice";

const INITIALVALUES = {
    image_res: "",
    video_res: "",
    screen_dots: "",
    screen_type: "",
    screen_size: "",
    touch_screen: "",
    video_format: "",
    image_format: "",
    max_video_res: "",
    viewfinder_type: "",
    image_ratio_w_h: "",
    viewfinder_coverage: ""
};

const CameraImageVideoDisplayForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createCameraImageVideoDisplay] = useCreateCameraImageVideoDisplayMutation();
    const [updateCameraImageVideoDisplay] = useUpdateCameraImageVideoDisplayMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateCameraImageVideoDisplay({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(3);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createCameraImageVideoDisplay({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(3);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="image_res"
                                label="Input camera image resolution"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="video_res"
                                label="Input camera video resolution"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="screen_dots"
                                label="Input camera screen dots"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="screen_type"
                                label="Input camera screen type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="screen_size"
                                label="Input camera screen size"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="touch_screen"
                                label="Input camera touch screen"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="video_format"
                                label="Input camera video format"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="image_format"
                                label="Input camera image format"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_video_res"
                                label="Input camera max video resolution"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="viewfinder_type"
                                label="Input camera viewfinder type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="image_ratio_w_h"
                                label="Input camera image ratio width & height"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="viewfinder_coverage"
                                label="Input camera viewfinder coverage"
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(1)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default CameraImageVideoDisplayForm;