import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const laptopProductApi = createApi({
    reducerPath: "laptopProductApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/product/laptop`,
        credentials: "include",
    }),
    tagTypes: ["laptop"],
    endpoints: (builder) => ({
        getLaptopById: builder.query({
            query: (id) => `/${id}`,
            providesTags: ["laptop"],
        }),
        createLaptopProcessor: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/processor",
            }),
            invalidatesTags: ["laptop"],
        }),
        updateLaptopProcessor: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/processor/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["laptop"],
        }),
        createLaptopMemoryStorage: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/memory-storage",
            }),
            invalidatesTags: ["laptop"],
        }),
        updateLaptopMemoryStorage: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/memory-storage/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["laptop"],
        }),
        createLaptopGraphicsDisplay: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/graphics-display",
            }),
            invalidatesTags: ["laptop"],
        }),
        updateLaptopGraphicsDisplay: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/graphics-display/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["laptop"],
        }),
        createLaptopPortsNetwork: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/ports-network",
            }),
            invalidatesTags: ["laptop"],
        }),
        updateLaptopPortsNetwork: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/ports-network/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["laptop"],
        }),
        createLaptopAudioKeyboard: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/audio-keyboard",
            }),
            invalidatesTags: ["laptop"],
        }),
        updateLaptopAudioKeyboard: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/audio-keyboard/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["laptop"],
        }),
        createLaptopPhysicalPower: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/physical-power",
            }),
            invalidatesTags: ["laptop"],
        }),
        updateLaptopPhysicalPower: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/physical-power/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["laptop"],
        }),
    }),
});

export const {
    useGetLaptopByIdQuery,
    useCreateLaptopProcessorMutation,
    useUpdateLaptopProcessorMutation,
    useCreateLaptopPortsNetworkMutation,
    useUpdateLaptopPortsNetworkMutation,
    useCreateLaptopMemoryStorageMutation,
    useUpdateLaptopMemoryStorageMutation,
    useCreateLaptopAudioKeyboardMutation,
    useUpdateLaptopAudioKeyboardMutation,
    useCreateLaptopPhysicalPowerMutation,
    useUpdateLaptopPhysicalPowerMutation,
    useCreateLaptopGraphicsDisplayMutation,
    useUpdateLaptopGraphicsDisplayMutation,
} = laptopProductApi;
