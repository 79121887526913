import notFound from "../../assets/nodatafound.png";
import { StyleDiv } from "./styles/DataNotFound.style";

const DataNotFound = () => {
    return (
        <StyleDiv>
            <img src={notFound} alt="Data Not Found" />
        </StyleDiv>
    );
};

export default DataNotFound;
