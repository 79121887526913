import { Form } from "formik";
import styled from "styled-components";

const StyleSection = styled.section`
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

const StyleForm = styled(Form)`
    gap: 12px;
    display: flex;
    background: #fff;
    padding: 30px 20px;
    flex-direction: column;
    box-shadow: rgba(5, 17, 80, 0.05) 0px -1px 15px -6px, 
                rgba(5, 17, 80, 0.1) 0px 8px 15px -5px;
`;

const StyleLoginButton = styled.button`
    color: #fff;
    border: none;
    outline: none;
    padding: 8px 0;
    cursor: pointer;
    margin-top: 8px;
    background: #27348b;
`;

export {
    StyleForm,
    StyleSection,
    StyleLoginButton
}