import { useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import CountrySelect from "../select/CountrySelect";
import {
    StyleInput,
    StyleLabel,
    StylePrice,
    StyleAddBtn,
    StyleAddSection,
    StyleAddPriceList
} from "./styles/AddProductPrice.style";

const AddProductPrice = ({
    prices,
    handleSetPrics
}) => {
    const [price, setPrice] = useState("");
    const [country, setCountry] = useState("");

    const handleAddPrice = () => {
        if(!price) {
            return toast.error("Please add price.");
        }

        if(!country) {
            return toast.error("Please add country");
        }

        const data = {
            price,
            country,
            id: new Date().getMilliseconds()
        };

        handleSetPrics([...prices, data]);

        setPrice("");
        setCountry("");
    }

    const handleRemovePrice = (value) => {
        const priceList = prices?.filter(item => item.id !== value.id);

        handleSetPrics(priceList);
    }

    return (
        <div>
            <div>
                <StyleLabel>
                    Add Product price by country
                </StyleLabel>

                <StyleAddSection>
                    <StyleInput 
                        type="number" 
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />

                    <CountrySelect 
                        country={country}
                        setCountry={(value) => setCountry(value)}
                    />

                    <StyleAddBtn 
                        type="button"
                        onClick={handleAddPrice}
                    >
                        Add
                    </StyleAddBtn>
                </StyleAddSection>
            </div>

            <StyleAddPriceList>
                {prices?.map(item => (
                    <StylePrice
                        key={item?.id}
                    >
                        <p>
                            {item?.country}
                        </p>

                        <p>
                            {item?.price}
                        </p>

                        <button 
                            type="button"
                            onClick={() =>handleRemovePrice(item)}
                        >
                            <Icon icon="iconamoon:close" rotate={3} vFlip={true} />
                        </button>
                    </StylePrice>
                ))}
            </StyleAddPriceList>
        </div>
    );
}
 
export default AddProductPrice;