import styled from "styled-components";

const StyleContainer = styled.section`
    width: 100%;
    position: relative;
`;

const StyleMainSection = styled.main`
    left: 300px;
    transition: 0.5s;
    position: absolute;
    width: calc(100% - 300px);
    background-color: #eaeeee;

    &.active{
        left: 50px;
        width: calc(100% - 50px);
    }

    @media screen and (max-width: 992px) {
        width: calc(100% - 50px);
        left: 50px;

        &.active{
            width: calc(100% - 300px);
            left: 300px;
        }
    }
`;

const StyleContentSection = styled.section`
    width: 100%;
    padding-top: 20px;
    padding-left: 12px;
    padding-right: 12px;
    background: #eaeeee;
    height: calc(100vh - 60px);
`;

export {
    StyleContainer,
    StyleMainSection,
    StyleContentSection
}