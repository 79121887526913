import { Col, Row } from "antd";
import { useState } from "react";
import { Icon } from "@iconify/react";
import FaqTextArea from "../inputs/FaqTextArea";
import { 
    StyleFaq,
    StyleSvgDiv,
    StyleFAQList,
    StyleAddBtnSection
} from "./styles/ProductFaq.style";

const ProductFaq = ({
    product_faqs,
    handleSetFaq
}) => {
    const [edit, setEdit] = useState(false);
    const [faq, setFaq] = useState({ title: "", description: "" });

    const handleChange = (event) => {
        const data = {
            ...faq,
            [event.target.name]: event.target.value
        };

        setFaq(data);
    }

    const handleDelete = (id) => {
        const filterData = product_faqs.filter(item => item.id !== id);

        handleSetFaq("product_faqs", filterData);
    }

    const handleEdit = (id) => {
        const [filterData] = product_faqs.filter(item => item.id === id);
        
        setEdit(true);
        setFaq(filterData);
    }

    const handleAddFaq = () => {
        const payload = {
            ...faq,
            id: faq?.id ? faq?.id : new Date().getMilliseconds(),
        };

        if(edit) {
            const faqList = product_faqs;

            const editAble = faqList.find(item => item.id == faq.id);

            editAble.title = payload.title;
            editAble.description = payload.description;

            setFaq({ title: "", description: "" });
            return handleSetFaq("product_faqs", faqList);
        }

        handleSetFaq("product_faqs", [payload, ...product_faqs]);

        setFaq({ title: "", description: "" });
    }

    return (
        <>
            {product_faqs?.length > 0 && (
                <Col xs={24}>
                    <StyleFAQList>
                        {product_faqs.map(item => (
                            <StyleFaq 
                                key={item.id} 
                                style={{ display: `${(edit && (item?.id === faq?.id)) ? "none" : "flex" }`}}
                            >
                                <div>
                                    <h4>
                                        {item.title}
                                    </h4>

                                    <p>
                                        {item.description}
                                    </p>
                                </div>

                                <StyleSvgDiv>
                                    <Icon 
                                        className="edit" 
                                        icon="material-symbols:edit" 
                                        onClick={() => handleEdit(item.id)}
                                    />
                                    <Icon 
                                        className="delete" 
                                        icon="ic:baseline-delete"  
                                        onClick={() => handleDelete(item.id)}
                                    />
                                </StyleSvgDiv>
                            </StyleFaq>
                        ))}
                    </StyleFAQList>
                </Col>
            )}

            <Col xs={24}>
                <Row gutter={[24, 12]}>
                    <Col xs={24} lg={12}>
                        <FaqTextArea 
                            error={null}
                            name="title"
                            value={faq?.title}
                            label="Add FAQ title"
                            onChange={handleChange}
                        />
                    </Col>

                    <Col xs={24} lg={12}>
                        <FaqTextArea 
                            error={null}
                            name="description"
                            onChange={handleChange}
                            value={faq?.description}
                            label="Add FAQ description"
                        />
                    </Col>

                    <Col xs={24}>
                        <StyleAddBtnSection>
                            <button 
                                type="button"
                                onClick={handleAddFaq}
                            >
                                Add FAQ
                            </button>
                        </StyleAddBtnSection>
                    </Col>
                </Row>
            </Col>
        </>
    );
}
 
export default ProductFaq;