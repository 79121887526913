import Layout from "../../../components/layout";
import ProductTable from "../components/ProductTable";
import { useNavigate, useLocation } from "react-router-dom";
import CreateLink from "../../../components/link/CreateLink";
import MainSection from "../../../components/section/MainSection";
import { useGetAllProductListQuery } from "../slices/product.slice";
import GlobalPagination from "../../../components/pagination/GlobalPagination";

const ProductList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");

    const { data, isError, isLoading } = useGetAllProductListQuery(
        page ? page : 1,
    );

    const handlePagination = (pageValue) => {
        navigate(`/product?page=${pageValue}`);
    };

    return (
        <Layout>
            <MainSection title="All Product List">
                <CreateLink
                    title="Create Product"
                    link="/product/create-product"
                />

                {data && !isError && !isLoading && (
                    <>
                        <ProductTable items={data.products} />

                        {data.metaData.total > data.metaData.limit && (
                            <GlobalPagination
                                total={data.metaData.total}
                                pageSize={data.metaData.limit}
                                handlePagination={handlePagination}
                                activePage={data.metaData.activePage}
                            />
                        )}
                    </>
                )}
            </MainSection>
        </Layout>
    );
};

export default ProductList;
