import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "../../../utilities/useQuery";
import ProductFaq from "../../../components/enlistForm/ProductFaq";
import BackAndGoForwad from "../../../components/button/BackAndGoForwad";
import ProductMainFeature from "../../../components/enlistForm/ProductMainFeature";
import ProductFeature from "../../../components/enlistForm/ProductFeature";
import SpecificationSection from "../../../components/section/SpecificationSection";
// import {
//     useUploadProductImageMutation,
//     useCreateProductMoreInfoMutation,
//     useUpdateProductMoreInfoMutation,
// } from "../../views/product/slices/product.slice";

const PRODUCT_FEATURES = [
    {
        category: "Launch",
        feature: [
            {
                title: "Announced",
                value: "2018, September",
            },
            {
                title: "Status",
                value: "Available. Released 2018, October",
            },
        ],
    },
    {
        category: "Network",
        feature: [
            {
                title: "Technology",
                value: "GSM / HSPA / LTE",
            },
        ],
    },
    {
        category: "Body",
        feature: [
            {
                title: "Dimensions",
                value: "157.9 x 76.4 x 8.3 mm (6.22 x 3.01 x 0.33 in)",
            },
            {
                title: "Weight",
                value: "182 g (6.42 oz)",
            },
            {
                title: "Build",
                value: "Glass front (Gorilla Glass), aluminum back, plastic frame",
            },
            {
                title: "SIM",
                value: "Hybrid Dual SIM (Nano-SIM, dual stand-by)",
            },
        ],
    },
    {
        category: "Display",
        feature: [
            {
                title: "Type",
                value: "IPS LCD",
            },
            {
                title: "Size",
                value: "6.26 inches, 97.8 cm2 (~81.1% screen-to-body ratio)",
            },
            {
                title: "Resolution",
                value: "1080 x 2280 pixels, 19:9 ratio (~403 ppi density)",
            },
            {
                title: "Protection",
                value: "Corning Gorilla Glass (unspecified version)",
            },
        ],
    },
    {
        category: "Platform",
        feature: [
            {
                title: "OS",
                value: "Android 8.1 (Oreo), planned upgrade to Android 10, MIUI 12",
            },
            {
                title: "Chipset",
                value: "Qualcomm SDM636 Snapdragon 636 (14 nm)",
            },
            {
                title: "CPU",
                value: "Octa-core (4x1.8 GHz Kryo 260 Gold & 4x1.6 GHz Kryo 260 Silver)",
            },
            {
                title: "GPU",
                value: "Adreno 509",
            },
        ],
    },
    {
        category: "Memory",
        feature: [
            {
                title: "Card slot",
                value: "microSDXC (uses shared SIM slot)",
            },
            {
                title: "Internal",
                value: "32GB 3GB RAM, 64GB 4GB RAM, 64GB 6GB RAM eMMC 5.1",
            },
        ],
    },
    {
        category: "Main Camera",
        feature: [
            {
                title: "Dual",
                value: "12 MP, f/1.9, 1/2.55, 1.4µm, dual pixel PDAF 5 MP, f/2.2, (depth)",
            },
            {
                title: "Features",
                value: "Dual-LED flash, HDR, panorama",
            },
            {
                title: "Video",
                value: "1080p@30fps",
            },
        ],
    },
];

const INITIALVALUES = {
    feature: [],
    main_feature: [],
};

const ProductFeatureForm = ({ update, handleBack, initialValues }) => {
    const query = useQuery();
    const id = query.get("id");
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const [loading, setLoading] = useState(true);
    // const [uploadProductImage] = useUploadProductImageMutation();
    // const [createProductMoreInfo] = useCreateProductMoreInfoMutation();
    // const [updateProductMoreInfo] = useUpdateProductMoreInfoMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        console.log(payload);

        // if (update && initialValues) {
        //     const { data, error } = await updateProductMoreInfo({
        //         data: payload,
        //         id,
        //     });

        //     if (error && !data) return toast.error("There was an error");

        //     if (data && !error) {
        //         action.resetForm();
        //         navigate("/product");

        //         return toast.success("Update successfully");
        //     }
        // }

        // const { data, error } = await createProductMoreInfo({
        //     data: payload,
        // });

        // if (error && !data) return toast.error("There was an error");

        // if (data && !error) {
        //     action.resetForm();
        //     navigate("/product");

        //     return toast.success("Create successfully");
        // }
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    console.log(initialValues);

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                    <Row gutter={[24, 12]}>
                        {console.log(values)}
                        <ProductMainFeature
                            setFieldValue={setFieldValue}
                            main_feature={values?.main_feature}
                        />

                        {/* <ProductFeature
                            setFieldValue={setFieldValue}
                            main_feature={values?.main_feature}
                        /> */}

                        <Col xs={24}>
                            <SpecificationSection
                                feature={values?.feature}
                                setFieldValue={setFieldValue}
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad
                                handleBack={handleBack}
                                title={
                                    update
                                        ? "Update & Go Product List"
                                        : "Create & Go Go Product List"
                                }
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default ProductFeatureForm;
