import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Table from "../../../components/table/Table";
import ContentSection from "../../../components/section/ContentSection";
import {
    useProductActiveMutation,
    useProductDeactiveMutation,
} from "../slices/product.slice";
import {
    StyleSlugTd,
    StyleActionTd,
    StyleStatusTd,
    StyleActionDiv,
    StyleCategoryTd,
    StyleProductImageTd,
} from "./styles/ProductTable.style";

const ProductTable = ({ items }) => {
    const [productActive] = useProductActiveMutation();
    const [productDeactive] = useProductDeactiveMutation();

    const handleProductStatus = async (product) => {
        if (product?.status === "deactive") {
            const { data, error } = await productActive({ id: product?.id });

            if (data && !error) {
                toast.success("Product active successfully");
            } else if (error) {
                toast.error(error);
            }
        } else if ("active") {
            const { data, error } = await productDeactive({ id: product?.id });

            if (data && !error) {
                toast.success("Product deactivate successfully");
            } else if (error) {
                toast.error(error);
            }
        }
    };

    const columns = [
        {
            label: "Image",
            path: "product_images",
            content: (item, path) => (
                <StyleProductImageTd>
                    <img alt={item.title} src={item[path][0].image_path} />
                </StyleProductImageTd>
            ),
        },
        {
            label: "Slug",
            path: "slug",
            sort: true,
            content: (item, path) => (
                <StyleSlugTd>
                    <div>{item[path]}</div>
                </StyleSlugTd>
            ),
        },
        {
            label: "Title",
            path: "title",
            sort: true,
            content: (item, path) => (
                <StyleSlugTd>
                    <div>{item[path]}</div>
                </StyleSlugTd>
            ),
        },
        {
            label: "Status",
            path: "status",
            sort: true,
            content: (item, path) => (
                <StyleStatusTd>{item[path]}</StyleStatusTd>
            ),
        },
        {
            label: "Category",
            path: "category",
            sort: true,
            content: (item, path) => (
                <StyleCategoryTd>{item[path]}</StyleCategoryTd>
            ),
        },
        {
            label: "Action",
            sort: true,
            content: (item) => (
                <StyleActionTd>
                    <StyleActionDiv>
                        <Link to={`/product/update-product/${item.id}`}>
                            <Icon icon="tabler:edit" />
                        </Link>

                        <button
                            type="button"
                            onClick={() => handleProductStatus(item)}
                        >
                            {item.status === "active" && (
                                <abbr title="Deactive">
                                    <Icon icon="fontisto:checkbox-active" />
                                </abbr>
                            )}

                            {item.status === "deactive" && (
                                <abbr title="Active">
                                    <Icon icon="fluent-mdl2:disable-updates" />
                                </abbr>
                            )}
                        </button>

                        <a href="www.google.com" target="_blank">
                            <Icon icon="carbon:view-filled" />
                        </a>
                    </StyleActionDiv>
                </StyleActionTd>
            ),
        },
    ];

    return (
        <ContentSection>
            <Table items={items} columns={columns} />
        </ContentSection>
    );
};

export default ProductTable;
