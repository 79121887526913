import Layout from "../../../components/layout";
import { useLocation, useNavigate } from "react-router-dom";
import CreateLink from "../../../components/link/CreateLink";
import MainSection from "../../../components/section/MainSection";
import UserTable from "../components/UserTable";
import { useGetAllUserListQuery } from "../slices/user.slice";

const UserList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");

    // const {} = useGetAllUserListQuery();

    // const handlePagination = (pageValue) => {
    //     navigate(`/user?page=${pageValue}`);
    // };

    // console.log(data);

    return (
        <Layout>
            <MainSection title="All User List">
                <CreateLink link="/" title="Create User" />

                <UserTable />
            </MainSection>
        </Layout>
    );
};

export default UserList;
