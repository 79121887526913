import styled from "styled-components";

const StyleHeader = styled.header`
    position: relative;
    width: 100%;
    height: 60px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: 5px 5px 3px rgba(0, 0, 0, .3);
`;

const StyleHeaderLeft = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        font-size: 25px;
        cursor: pointer;
        margin-right: 10px;
    }

    h4{
        font-size: 20px;
        font-weight: 500;
    }
`;

const StyleUserSection = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
`;

const StyleUserImage = styled.div`
    width: 45px;
    height: 45px;
    position: relative;
    margin-right: 10px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        position: absolute;
    }
`

export {
    StyleHeader,
    StyleUserImage,
    StyleHeaderLeft,
    StyleUserSection
}