import { Icon } from "@iconify/react";
import {
    StyleDiv,
    StyleCard,
    StyleButtonSection,
} from "./styles/MaxPriceCard.style";

const MaxPriceCard = ({ edit, data, handleEdit, handleDelete }) => {
    return (
        <StyleDiv>
            {data?.map((item) => (
                <StyleCard
                    key={item?.id}
                    style={{
                        display: `${
                            edit && item?.id === edit?.id ? "none" : "block"
                        }`,
                    }}
                >
                    <div>
                        <h4>{item.name} Max Price</h4>

                        <p>{item.max_price}</p>
                    </div>

                    <StyleButtonSection>
                        <Icon
                            className="edit"
                            icon="material-symbols:edit"
                            onClick={() => handleEdit(item)}
                        />
                        <Icon
                            className="delete"
                            icon="ic:baseline-delete"
                            onClick={() => handleDelete(item.id)}
                        />
                    </StyleButtonSection>
                </StyleCard>
            ))}
        </StyleDiv>
    );
};

export default MaxPriceCard;
