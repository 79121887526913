import { string, object, array } from "yup";

const CountrySchema = object().shape({
    title: string().required("This field must not be empty."),
    currency: string().required("This field must not be empty."),
    name: string().required("This field must not be empty."),
    category: array()
        .min(1, "At least add one category info.")
        .of(
            object().shape({
                name: string().required("This  field must not be empty."),
                max_price: string().required("This  field must not be empty."),
            }),
        )
        .required("This  field must not be empty."),
});

export default CountrySchema;
