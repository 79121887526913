import { useRef } from "react";
import { Row, Col } from "antd";
import { Formik, Form } from "formik";
import blogSchema from "../schema/blog.schema";
import { Editor } from "@tinymce/tinymce-react";
import TextArea from "../../../components/inputs/TextArea";
import InputField from "../../../components/inputs/InputField";
import ImageUpload from "../../../components/inputs/ImageUpload";
import SubmitButton from "../../../components/button/SubmitButton";
import CategorySelect from "../../../components/select/CategorySelect";
import { useUploadBlogImageMutation } from "../slices/getBlogList.slice";
import { useGetAllCategoryListQuery } from "../../category/slices/category.slice";

const INITIALVALUES = {
    slug: "",
    title: "",
    meta_title: "",
    sort_desc: "",
    meta_desc: "",
    category: "",
    banner_image: "",
    description: "",
};

const BlogForm = ({ update, handleSubmit, initialValues }) => {
    const editorRef = useRef(null);
    const [uploadBlogImage] = useUploadBlogImageMutation();
    const { data, isError, isLoading } = useGetAllCategoryListQuery();

    const uploadImage = async (file, cb) => {
        try {
            const { data, error } = await uploadBlogImage(file);

            !error && data && cb(data?.url, { title: "Amanullha zoha" });
        } catch (error) {
            console.log(error);
        }
    };

    const handleBannerImage = async (event, setFieldValue) => {
        try {
            const file = event.target.files[0];
            const { data, error } = await uploadBlogImage(file);

            !error && data && setFieldValue("banner_image", data?.url);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Formik
            validationSchema={blogSchema}
            initialValues={initialValues ? initialValues : INITIALVALUES}
            onSubmit={(values, action) =>
                handleSubmit(action, {
                    ...values,
                    description: editorRef.current.getContent(),
                })
            }
        >
            {({ handleSubmit, setFieldValue, values }) => (
                <Form onSubmit={handleSubmit}>
                    <Row gutter={[24, 12]}>
                        <Col xs={24}>
                            <ImageUpload
                                name="banner_image"
                                setFieldValue={setFieldValue}
                                handleChange={(event) =>
                                    handleBannerImage(event, setFieldValue)
                                }
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField name="title" label="Write blog title" />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField
                                name="meta_title"
                                label="Write blog meta title"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <TextArea
                                name="sort_desc"
                                label="Write blog sort description"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <TextArea
                                name="meta_desc"
                                label="Write blog meta description"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField name="slug" label="Write blog slug" />
                        </Col>

                        <Col xs={24} lg={12}>
                            {/* <InputField 
                                name="category"
                                label="Write blog category"
                            /> */}
                            <CategorySelect
                                category={values?.category}
                                categoryList={data?.category}
                                setCategory={(value) =>
                                    setFieldValue("category", value)
                                }
                            />
                        </Col>

                        <Col xs={24}>
                            <Editor
                                apiKey="gj9ei06xxexf7bhbmdm2o1pnsudmnpn7nmfkzzmc1tjj41ku"
                                initialValue={values?.description}
                                onInit={(evt, editor) =>
                                    (editorRef.current = editor)
                                }
                                init={{
                                    content_style: "body { background: #fff; }",
                                    color_map: [
                                        "#ffffff",
                                        "White",
                                        "#BEBEBE",
                                        "gray",
                                        "#ffffff",
                                        "White",
                                    ],
                                    height: "500px",
                                    font_size_formats:
                                        "8px 10px 12px 14px 16px 18px 20px 24px 34px 48px 51px",
                                    plugins:
                                        "link image table emoticons code preview importcss tinydrive image autolink visualblocks visualchars fullscreen lists link media template charmap table emoticons directionality ",
                                    toolbar:
                                        "undo redo | fontsizeselect | fontsize | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor emoticons | link image media | code preview",
                                    branding: false,
                                    file_picker_types: "image",
                                    file_picker_callback: function (
                                        cb,
                                        value,
                                        meta,
                                    ) {
                                        const input =
                                            document.createElement("input");

                                        input.setAttribute("type", "file");
                                        input.setAttribute("accept", "image/*");
                                        input.onchange = function () {
                                            const file = this.files[0];
                                            const reader = new FileReader();

                                            reader.onload = function () {
                                                uploadImage(file, cb);
                                            };
                                            reader.readAsDataURL(file);
                                        };

                                        input.click();
                                    },
                                    relative_url: false,
                                    automatic_uploads: true,
                                }}
                            />
                        </Col>

                        <Col xs={24}>
                            <SubmitButton
                                title={update ? "Update" : "Submit"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default BlogForm;
