import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateDroneGimbalFlightBatteryChargerMutation, 
    useUpdateDroneGimbalFlightBatteryChargerMutation
} from "../../views/product/slices/droneProduct.slice";

const INITIALVALUES = {
    max_capacity: "",
    power_rating: "",
    control_range: "",
    angular_speed: "",
    output_voltage: "",
    number_of_axis: "",
    battery_chemistry: "",
    max_charging_power: "",
    charging_temperature: ""
};

const DroneGimbalFlightBatteryForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createDroneGimbalFlightBatteryCharger] = useCreateDroneGimbalFlightBatteryChargerMutation();
    const [updateDroneGimbalFlightBatteryCharger] = useUpdateDroneGimbalFlightBatteryChargerMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateDroneGimbalFlightBatteryCharger({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(4);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createDroneGimbalFlightBatteryCharger({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(4);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_capacity"
                                label="Input drone max capacity"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="power_rating"
                                label="Input drone power rating"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="control_range"
                                label="Input drone control range"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="angular_speed"
                                label="Input drone angular speed"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="output_voltage"
                                label="Input drone output voltage"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="number_of_axis"
                                label="Input drone number of axis"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery_chemistry"
                                label="Input drone battery chemistry"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_charging_power"
                                label="Input drone max charging power"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="charging_temperature"
                                label="Input drone charging temperature"
                            />
                        </Col> 

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(2)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default DroneGimbalFlightBatteryForm;