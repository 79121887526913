import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const permissionApi = createApi({
    reducerPath: "permissionApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/permissions`,
        credentials: "include",
        tagTypes: ["permissions", "permission"],
    }),
    endpoints: (builder) => ({
        getAllPermissionList: builder.query({
            query: (page = 1) => `?page=${page}`,
            invalidatesTags: ["permission"],
        }),
    }),
});

export const { useGetAllPermissionListQuery } = permissionApi;
