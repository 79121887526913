import { useField } from "formik";
import { 
    StyleLabel,
    StyleTextArea 
} from "./styles/TextArea.style";

const TextArea = ({
    label,
    className,
    placeholder,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <div>
            {label && (
                <StyleLabel htmlFor={props.name}>
                    {label}
                </StyleLabel> 
            )}

            <StyleTextArea 
                {...field}
                className={className}
                placeholder={placeholder}
            />

            {meta?.error && meta?.touched ? <p>{meta.error}</p> : null}
        </div>
    );
}
 
export default TextArea;