import styled from "styled-components";

const StyleCreateLink = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    justify-content: flex-end;
    
    a{
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        background: black;
        padding: 5px 20px;
        margin-right: 10px;
        border: 1px solid black;
    }
`;

export {
    StyleCreateLink
}