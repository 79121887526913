import { useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../../components/layout";
import ProductForm from "../components/ProductForm";
import { useNavigate, useParams } from "react-router-dom";
import EnlistStep from "../../../components/steps/EnlistStep";
import MainSection from "../../../components/section/MainSection";
import ProductFeature from "../../../components/enlistForm/ProductFeature";
import ProductMoreInfoForm from "../../../components/enlistForm/ProductMoreInfo";
import {
    useGetProductByIdQuery,
    useUpdateProductMutation,
} from "../slices/product.slice";

const UpdateProduct = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [updateProduct, { status }] = useUpdateProductMutation();
    const { data, isLoading, isError, error } = useGetProductByIdQuery(id);

    const handleSubmit = async (action, data) => {
        const res = await updateProduct({ data, id });

        if (res) {
            setActiveStep(1);

            action.resetForm();
        }
    };

    const STEPS = [
        {
            title: (
                <span onClick={() => activeStep > 0 && setActiveStep(0)}>
                    Basic
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 1 && setActiveStep(1)}>
                    Feature
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 2 && setActiveStep(2)}>
                    More Info
                </span>
            ),
        },
    ];

    if (isError) {
        toast.error(error.data);
    }

    if (status === "fulfilled") {
        // toast.success("Successfuly product update.");

        if (data) {
            // setActiveStep(1);
            // return navigate(`/product/update-product/${data?.id}`);
        }
    }

    console.log(data);

    return (
        <Layout>
            <MainSection title="Update Product">
                {data && !isLoading && !isError && (
                    <>
                        <EnlistStep
                            items={STEPS}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                        />

                        {activeStep === 0 && (
                            <ProductForm
                                update={true}
                                initialValues={{
                                    ...data,
                                    prices: data?.product_prices,
                                    images: data?.product_images,
                                }}
                                handleSubmit={handleSubmit}
                            />
                        )}

                        {activeStep === 1 && (
                            // <h1>Hello world</h1>
                            <ProductFeature />
                        )}

                        {activeStep === 2 && (
                            <h1>Hello world</h1>
                            // <ProductMoreInfoForm handleSubmit={handleSubmit} />
                        )}
                    </>
                )}
            </MainSection>
        </Layout>
    );
};

export default UpdateProduct;
