import { useRef } from "react";
import { useField } from "formik";
import { Icon } from "@iconify/react";
import imageIcon from "../../assets/imageIcon.png";
import {
    StyleLabel,
    StyleBannerImage,
    StyleImageSection,
    StyleDeleteButton
} from "./styles/ImageUpload.style";

const ImageUpload = ({
    handleChange,
    setFieldValue,
    ...props
}) => {
    const fileRef = useRef();
    const [field, meta] = useField(props);

    const handleInput = () => {
        if(!field.value) {
            return fileRef.current.click();
        }
    }

    return (
        <>
            <StyleLabel>
                Upload blog banner image
            </StyleLabel>

            <input 
                hidden
                type="file" 
                ref={fileRef} 
                accept="image/*"
                name={field.name}
                onChange={handleChange}
            />

            {!field.value && (
                <StyleImageSection
                    onClick={handleInput}
                >
                    {!field.value && <img src={imageIcon} alt="image-icon" />}
                </StyleImageSection>
            )}

            {field.value && (
                <StyleImageSection>
                    {field.value && <StyleBannerImage src={field.value} alt="image-icon" />}
                    {field.value && (
                        <StyleDeleteButton
                            type="button"
                            onClick={() => setFieldValue("banner_image", "")}
                        >
                            <Icon icon="material-symbols:delete-outline" />
                        </StyleDeleteButton>
                    )}
                </StyleImageSection>
            )}

            {(meta?.error && meta?.touched ) ? <p>{meta.error}</p> : null}
        </>
    );
}
 
export default ImageUpload;