import styled from "styled-components";

const StyleInfoCard = styled.div`
    color: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    background: #5d6491;
    justify-content: space-between;
    box-shadow: 2px 3px 2px rgba(0, 0, 0, .3);

    svg{
        font-size: 35px;
    }
`;

const StyleDiv = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const StyleTitle = styled.p`
    font-size: 18px;
    font-weight: 500;
`;

const StyleCount = styled.p`
    font-size: 24px;
    font-weight: 600;
`;

export {
    StyleDiv,
    StyleCount,
    StyleTitle,
    StyleInfoCard
}