import { Navigate, Outlet } from "react-router-dom";
import { useGetProfileQuery } from "../../views/login/slices/login.slice";

const PublicRoute = () => {
    const { isLoading, isSuccess, data } = useGetProfileQuery();

    return (
        <>
            {(isLoading && !isSuccess) ? (
                <h1>Loading</h1>
            ) : (
                (isSuccess && data) ? <Navigate to="/" /> : <Outlet />
            )}
        </>
    )
}
 
export default PublicRoute;