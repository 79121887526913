import { Link } from "react-router-dom";
import { StyleCreateLink } from "./styles/CreateLink.style";

const CreateLink = () => {
    return (
        <StyleCreateLink>
            <Link to="/blog/create-blog">Create Blog</Link>
        </StyleCreateLink>
    );
}
 
export default CreateLink;