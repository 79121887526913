import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import InputField from "../inputs/InputField";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateLaptopAudioKeyboardMutation,
    useUpdateLaptopAudioKeyboardMutation
} from "../../views/product/slices/laptopProduct.slice";

const INITIALVALUES = {
    webcam: "",
    speaker: "",
    microphone: "",
    rgb_keyboard: "",
    security_chip: "",
    face_detection: "",
    keyboard_layout: "",
    audio_properties: "",
    keyboard_back_lit: "",
    finger_print_sensor: "",
    licensed_application: "",
    dedicated_navigation_key: ""
};

const LaptopAudioKeyboardForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createLaptopAudioKeyboard] = useCreateLaptopAudioKeyboardMutation();
    const [updateLaptopAudioKeyboard] = useUpdateLaptopAudioKeyboardMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateLaptopAudioKeyboard({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(5);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createLaptopAudioKeyboard({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(5);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <InputField 
                                name="webcam"
                                label="Input laptop webCam support"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="speaker"
                                label="Input laptop speaker support"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="microphone"
                                label="Input laptop microphone support"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="rgb_keyboard"
                                label="Input laptop RGB keyboard"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="security_chip"
                                label="Input laptop security chip"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="audio_properties"
                                label="Input laptop audio properties"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="finger_print_sensor"
                                label="Input laptop finger print sensor"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="face_detection"
                                label="Input laptop face detection"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="keyboard_layout"
                                label="Input laptop keyboard layout"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="keyboard_back_lit"
                                label="Input laptop keyboard back lit"
                            />
                        </Col> 

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(3)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default LaptopAudioKeyboardForm;