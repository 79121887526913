import Table from "../../../components/table/Table";
import ContentSection from "../../../components/section/ContentSection";

const ContactTable = ({ items }) => {
    const columns = [
        {
            label: "Phone",
            path: "number",
            content: (item, path) => <td>{item[path]}</td>,
        },
        {
            label: "Email",
            path: "email",
            content: (item, path) => <td>{item[path]}</td>,
        },
        {
            label: "Subject",
            path: "subject",
            content: (item, path) => <td>{item[path]}</td>,
        },
        {
            label: "Status",
            path: "status",
            content: (item, path) => <td>{item[path]}</td>,
        },
    ];

    return (
        <ContentSection>
            <Table columns={columns} items={items} />
        </ContentSection>
    );
};

export default ContactTable;
