import styled from "styled-components";

const StyleTable = styled.table`
    width: 100%;
    /* min-height: 300px; */
    border: none;
    border: none;
    background: #fff;
    border-collapse: collapse;

    th,
    td {
        border: none;
    }

    thead {
        border-bottom: 2px solid black;

        th {
            padding: 5px 0;
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                background: #fff;
            }

            &:nth-child(odd) {
                background: #f6f6f5;
            }

            &:hover {
                cursor: pointer;
                /* background: rgba(0, 0, 0, 0.3); */
            }
        }
    }
`;

export { StyleTable };
