import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const brandApi = createApi({
    reducerPath: "brandApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/brand`,
        credentials: "include",
        tagTypes: ["brands", "brand"],
    }),
    endpoints: (builder) => ({
        getAllBrandList: builder.query({
            query: (page = 1) => `?page=${page}`,
            providesTags: ["brands"],
        }),
        getBrandByID: builder.query({
            query: (id) => `/${id}`,
            providesTags: ["brands", "brand"],
        }),
        createBrand: builder.mutation({
            query: (data) => ({
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["brands"],
        }),
        updateBrandById: builder.mutation({
            query: ({ id, data }) => ({
                url: `/${id}`,
                body: data,
                method: "PUT",
            }),
            invalidatesTags: ["brands", "brand"],
        }),
    }),
});

export const {
    useGetBrandByIDQuery,
    useCreateBrandMutation,
    useGetAllBrandListQuery,
    useUpdateBrandByIdMutation,
} = brandApi;
