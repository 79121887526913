import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateCameraPerformanceFlashMutation,
    useUpdateCameraPerformanceFlashMutation
} from "../../views/product/slices/cameraProduct.slice";

const INITIALVALUES = {
    iso: "",
    max_iso: "",
    flash_range: "",
    focus_points: "",
    manual_focus: "",
    palyback_zoom: "",
    shutter_speed: "",
    built_in_flash: "",
    external_flash: "",
    flash_x_sync_speed: "",
    autofocus_assist_lamp: ""
};

const CameraPerformanceFlashForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createCameraPerformanceFlash] = useCreateCameraPerformanceFlashMutation();
    const [updateCameraPerformanceFlash] = useUpdateCameraPerformanceFlashMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateCameraPerformanceFlash({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(2);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createCameraPerformanceFlash({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(2);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="iso"
                                label="Input camera ISO"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_iso"
                                label="Input camera max ISO"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="flash_range"
                                label="Input camera flash range"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="focus_points"
                                label="Input camera focus points"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="manual_focus"
                                label="Input camera manual focus"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="palyback_zoom"
                                label="Input camera palyback zoom"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="shutter_speed"
                                label="Input camera shutter speed"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="built_in_flash"
                                label="Input camera built in flash"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="external_flash"
                                label="Input camera external flash"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="flash_x_sync_speed"
                                label="Input camera flash X sync speed"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="autofocus_assist_lamp"
                                label="Input camera autofocus assist lamp"
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(0)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default CameraPerformanceFlashForm;