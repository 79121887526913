import styled from "styled-components";

const StyleContentSection = styled.div`
    min-height: 80%;
    padding: 20px 15px;
    margin-right: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.3);
`;

export { StyleContentSection };
