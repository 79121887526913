import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout";
import ProductForm from "../components/ProductForm";
import EnlistStep from "../../../components/steps/EnlistStep";
import MainSection from "../../../components/section/MainSection";
import { useCreateProductMutation } from "../slices/product.slice";
import ProductFeature from "../../../components/enlistForm/ProductFeature";
import ProductMoreInfoForm from "../../../components/enlistForm/ProductMoreInfo";

const CreateProduct = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(2);
    const [createProduct, { status, isError, error, data }] =
        useCreateProductMutation();

    const STEPS = [
        {
            title: (
                <span onClick={() => activeStep > 0 && setActiveStep(0)}>
                    Basic
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 1 && setActiveStep(1)}>
                    Feature
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 2 && setActiveStep(2)}>
                    More Info
                </span>
            ),
        },
    ];

    const handleSubmit = async (action, data) => {
        const res = await createProduct({ data });

        if (res) {
            action.resetForm();

            setActiveStep(1);
        }
    };

    if (isError) {
        toast.error(error.data);
    }

    if (status === "fulfilled") {
        // toast.success("Successfully basic created.");

        if (data) {
            return navigate(`/product/create-product?id=${data?.id}`);
        }
    }

    return (
        <Layout>
            <MainSection title="Create Product">
                <EnlistStep
                    items={STEPS}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                />

                {activeStep === 0 && (
                    <ProductForm handleSubmit={handleSubmit} />
                )}

                {activeStep === 1 && <ProductFeature />}

                {activeStep === 2 && (
                    <ProductMoreInfoForm handleSubmit={handleSubmit} />
                )}
            </MainSection>
        </Layout>
    );
};

export default CreateProduct;
