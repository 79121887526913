import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const profileApi = createApi({
    reducerPath: "profileApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/profiles`,
        credentials: "include",
        tagTypes: ["profiles", "profile"],
    }),
    endpoints: (builder) => ({
        getAllProfileList: builder.query({
            query: (page = 1) => `?page=${page}`,
            invalidatesTags: ["profile"],
        }),
    }),
});

export const { useGetAllProfileListQuery } = profileApi;
