import { Row, Col } from "antd";
import { Fragment } from "react";
import TBody from "../table/Tbody";
import CategoryModal from "../Modal/CategoryModal";
import SpecificationThead from "../table/SpecificationThead";
import {
    StyleTable,
    StyleHeading,
    StyleHeadingSection,
    StyleContentSection,
} from "./styles/SpecificationSection.style";
import CategoryValueAddModal from "../Modal/CategoryValueAddModal";
import SpecificationDeleteConfirmModal from "../Modal/SpecificationDeleteConfirmModal";

const SpecificationSection = ({ feature, setFieldValue }) => {
    const handleAddCategory = () => {
        const data = feature;
        data.push({
            category: "Zoha",
            id: new Date().getMilliseconds(),
            feature: [
                {
                    title: "My Title",
                    value: "My Value",
                    id: new Date().getMilliseconds() + 1,
                },
            ],
        });

        setFieldValue("feature", data);
    };

    const handeleEditTitle = (item) => {
        const data = feature;

        function filterFeaturesByTitle(data, title) {
            return data.flatMap((item) => {
                if (item.feature.find((feature) => feature.title === title)) {
                    const findData = item.feature.filter((feature) => {
                        if (feature.title === title) {
                            feature.title = "Amanullha";
                            feature.value = "Zoha";

                            return feature;
                        } else {
                            return feature;
                        }
                    });

                    return findData;
                }
                {
                    return item;
                }
            });
        }

        // Example: Filter features with title "OS"
        const filteredFeatures = filterFeaturesByTitle(data, item?.title);

        console.log(filteredFeatures, data);
        setFieldValue("feature", data);
    };

    const handeleDeleteTitle = (item) => {
        console.log(item);
    };

    const columns = [
        {
            path: "title",
            content: (item, path) => <td>{item[path]}</td>,
        },
        {
            path: "value",
            content: (item, path) => (
                <td>
                    <div>
                        {item[path]}
                        <button
                            type="button"
                            onClick={() => handeleEditTitle(item)}
                        >
                            Edit
                        </button>
                        <button
                            type="button"
                            onClick={() => handeleDeleteTitle(item)}
                        >
                            Delete
                        </button>
                    </div>
                </td>
            ),
        },
    ];

    return (
        <StyleContentSection>
            <StyleHeadingSection>
                <StyleHeading>Specification</StyleHeading>
                <button type="button" onClick={handleAddCategory}>
                    Add Category
                </button>
            </StyleHeadingSection>

            <StyleTable>
                {feature?.map((item, index) => (
                    <Fragment key={index + 1}>
                        <SpecificationThead heading={item?.category} />

                        <TBody items={item?.feature} columns={columns} />
                    </Fragment>
                ))}
            </StyleTable>

            <CategoryModal />
            <CategoryValueAddModal />
            <SpecificationDeleteConfirmModal />
        </StyleContentSection>
    );
};

export default SpecificationSection;
