import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Table from "../../../components/table/Table";
import ActionLayout from "../../../components/layout/ActionLayout";
import ContentSection from "../../../components/section/ContentSection";

const CountryTable = ({ items }) => {
    const columns = [
        {
            label: "Country Name",
            path: "name",
            content: (item, path) => (
                <td style={{ width: "20%" }}>{item[path]}</td>
            ),
        },
        {
            label: "Currency",
            path: "currency",
            content: (item, path) => (
                <td style={{ width: "20%" }}>{item[path]}</td>
            ),
        },
        {
            label: "Title",
            path: "title",
            sort: true,
            content: (item, path) => <td>{item[path]}</td>,
        },
        {
            label: "ID",
            path: "id",
            sort: true,
            content: (item, path) => <td>{item[path]}</td>,
        },
        {
            label: "Status",
            path: "status",
            sort: true,
            content: (item, path) => <td>{item[path]}</td>,
        },
        {
            label: "Action",
            sort: true,
            content: (item) => (
                <ActionLayout>
                    <Link to={`/country/update/${item.id}`}>
                        <Icon icon="tabler:edit" />
                    </Link>

                    {/* <a href="www.google.com" target="_blank">
                        <Icon icon="carbon:view-filled" />
                    </a> */}
                </ActionLayout>
            ),
        },
    ];

    return (
        <ContentSection>
            <Table columns={columns} items={items} />
        </ContentSection>
    );
};

export default CountryTable;
