import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout";
import CountryForm from "../components/CountryForm";
import MainSection from "../../../components/section/MainSection";
import { useCreateCountryMutation } from "../slices/country.slice";

const CreateCountry = () => {
    const navigate = useNavigate();
    const [createCountry, { isLoading, isError, error, status }] =
        useCreateCountryMutation();

    const handleSubmit = (action, data) => createCountry(data);

    if (isError) {
        toast.error(error.data);
    }

    if (status === "fulfilled") {
        toast.success("Successfully country created.");
        navigate("/country");
    }

    return (
        <Layout>
            <MainSection title="Create Country">
                <CountryForm handleSubmit={handleSubmit} />
            </MainSection>
        </Layout>
    );
};

export default CreateCountry;
