const SpecificationThead = ({ heading }) => {
    return (
        <thead>
            <tr>
                <th rowSpan={2} colSpan={2} style={{ textAlign: "left" }}>
                    {heading}
                </th>
            </tr>
        </thead>
    );
};

export default SpecificationThead;
