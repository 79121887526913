import Thead from "./Thead";
import TBody from "./Tbody";
import { StyleTable } from "./styles/Table.style";

const Table = ({ items = [], columns }) => {
    return (
        <StyleTable>
            <Thead columns={columns} />

            <TBody items={items} columns={columns} />
        </StyleTable>
    );
};

export default Table;
