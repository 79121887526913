import { Link } from "react-router-dom";
import { StyleCreateLink } from "./styles/CreateLink.style";

const CreateLink = ({
    link,
    title
}) => {
    return (
        <StyleCreateLink>
            <Link to={link}>
                {title}
            </Link>
        </StyleCreateLink>
    );
}
 
export default CreateLink;