import { Row, Col } from "antd";
import { Icon } from "@iconify/react";
import InfoCard from "../../../components/card/InfoCard";

const InfoCardSection = () => {
    return (
        <Row gutter={[24, 24]}>
            <Col xs={24} sm={12} lg={6}>
                <InfoCard 
                    count={160}
                    title="Products"
                    icon={<Icon icon="eos-icons:products" />}
                />
            </Col>

            <Col xs={24} sm={12} lg={6}>
                <InfoCard 
                    count={145}
                    title="Blogs"
                    icon={<Icon icon="carbon:blog" />}
                />
            </Col>

            <Col xs={24} sm={12} lg={6}>
                <InfoCard 
                    count={16}
                    title="Users"
                    icon={<Icon icon="solar:user-bold" />}
                />
            </Col>

            <Col xs={24} sm={12} lg={6}>
                <InfoCard 
                    count={100160}
                    title="Visitors"
                    icon={<Icon icon="fluent-mdl2:mobile-angled" />}
                />
            </Col>
        </Row>
    );
}
 
export default InfoCardSection;