import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const contactApi = createApi({
    reducerPath: "contactApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/contact`,
        credentials: "include",
        tagTypes: ["contact", "contacts"],
    }),
    endpoints: (builder) => ({
        getAllContactList: builder.query({
            query: (page = 1) => `?page=${page}`,
            providesTags: ["contacts"],
        }),
        getContactByID: builder.mutation({
            query: ({ id }) => `/${id}`,
            providesTags: ["contact", "contacts"],
        }),
        deleteContactByID: builder.mutation({
            query: ({ id }) => ({
                url: `/${id}`,
                method: "delete",
            }),
            invalidatesTags: ["contacts", "contact"],
        }),
    }),
});

export const { useGetAllContactListQuery } = contactApi;
