import { Icon } from "@iconify/react";
import { 
    StyleImageDiv,
    StyleDeleteButton 
} from "./styles/ImageCard.style";

const ImageCard = ({
    url,
    handleDeleteImage
}) => {
    return (
        <StyleImageDiv>
            <img 
                src={url}
                alt="product_image"
            />

            <StyleDeleteButton
                type="button"
                onClick={handleDeleteImage}
            >
                <Icon icon="material-symbols:delete-outline" />
            </StyleDeleteButton>
        </StyleImageDiv>
    );
}
 
export default ImageCard;