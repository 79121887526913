import { useRef } from "react";
import { Row, Col } from "antd";
import { Formik, Form } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import TextArea from "../../../components/inputs/TextArea";
import InputField from "../../../components/inputs/InputField";
import FAQSection from "../../../components/section/FAQSection";
import SubmitButton from "../../../components/button/SubmitButton";
import CategorySelect from "../../../components/select/CategorySelect";
import { useGetAllCategoryListQuery } from "../../category/slices/category.slice";
import { useUploadProductImageMutation } from "../../product/slices/product.slice";

const INITIALVALUES = {
    name: "",
    title: "",
    category: "",
    brand_faq: [],
    more_info: [],
    meta_title: "",
    description: "",
    meta_description: "",
    short_description: "",
};

const BrandForm = ({ update, handleSubmit, initialValues }) => {
    const editorRef = useRef(null);
    const [uploadProductImage] = useUploadProductImageMutation();
    const { data, isError, isLoading } = useGetAllCategoryListQuery();

    const uploadImage = async (file, cb) => {
        try {
            const { data, error } = await uploadProductImage(file);

            !error && data && cb(data?.url, { title: "bplabels.com" });
        } catch (error) {
            console.log(error);
        }
    };

    console.log(data);

    return (
        <Formik
            // validationSchema={blogSchema}
            onSubmit={(values, action) =>
                handleSubmit(action, {
                    ...values,
                    description: editorRef.current.getContent(),
                })
            }
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit, setFieldValue, values }) => (
                <Form onSubmit={handleSubmit}>
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <CategorySelect
                                category={values?.category}
                                categoryList={data?.category}
                                setCategory={(value) =>
                                    setFieldValue("category", value)
                                }
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField name="name" label="Write brand name" />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField
                                name="title"
                                label="Write brand title"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField
                                name="meta_title"
                                label="Write brand meta title"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <TextArea
                                name="short_description"
                                label="Write brand short description"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <TextArea
                                name="meta_description"
                                label="Write brand meta description"
                            />
                        </Col>

                        <Col xs={24}>
                            <FAQSection
                                formTitle="Add a more info"
                                buttonTitle="Add More Info"
                                title="Brand more information"
                                dataList={values?.more_info}
                                handleSetValue={(value) =>
                                    setFieldValue("more_info", value)
                                }
                            />
                        </Col>

                        <Col xs={24}>
                            <FAQSection
                                title="Brand FAQ"
                                buttonTitle="Add FAQ"
                                formTitle="Add a FQA"
                                dataList={values?.brand_faq}
                                handleSetValue={(value) =>
                                    setFieldValue("brand_faq", value)
                                }
                            />
                        </Col>

                        <Col xs={24}>
                            <Editor
                                initialValue={values?.description}
                                apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                                onInit={(evt, editor) =>
                                    (editorRef.current = editor)
                                }
                                init={{
                                    content_style: "body { background: #fff; }",
                                    color_map: [
                                        "#ffffff",
                                        "White",
                                        "#BEBEBE",
                                        "gray",
                                        "#ffffff",
                                        "White",
                                    ],
                                    height: "500px",
                                    font_size_formats:
                                        "8px 10px 12px 14px 16px 18px 20px 24px 34px 48px 51px",
                                    plugins:
                                        "link image table emoticons code preview importcss tinydrive image autolink visualblocks visualchars fullscreen lists link media template charmap table emoticons directionality ",
                                    toolbar:
                                        "undo redo | fontsizeselect | fontsize | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor emoticons | link image media | code preview",
                                    branding: false,
                                    file_picker_types: "image",
                                    file_picker_callback: function (
                                        cb,
                                        value,
                                        meta,
                                    ) {
                                        const input =
                                            document.createElement("input");

                                        input.setAttribute("type", "file");
                                        input.setAttribute("accept", "image/*");
                                        input.onchange = function () {
                                            const file = this.files[0];
                                            const reader = new FileReader();

                                            reader.onload = function () {
                                                uploadImage(file, cb);
                                            };
                                            reader.readAsDataURL(file);
                                        };

                                        input.click();
                                    },
                                    relative_url: false,
                                    automatic_uploads: true,
                                }}
                            />
                        </Col>

                        <Col xs={24}>
                            <SubmitButton
                                title={update ? "Update" : "Submit"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default BrandForm;
