import styled from "styled-components";

const StyleMainSection = styled.section`
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 30px;
    height: calc(100vh - 80px);
`;

const StyleHeading = styled.h3`
    color: 374151;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 14px;
`;

export {
    StyleHeading,
    StyleMainSection
}