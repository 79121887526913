import StyleEnlistStep from "./styles/EnlistStep.style";

const EnlistStep = ({ 
    items, 
    activeStep, 
    setActiveStep
}) => {
    return (
        <StyleEnlistStep 
            size="small" 
            items={items} 
            responsive={false} 
            current={activeStep} 
            labelPlacement="vertical" 
        />
    );
}

export default EnlistStep;