import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import InputField from "../inputs/InputField";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateLaptopMemoryStorageMutation,
    useUpdateLaptopMemoryStorageMutation
} from "../../views/product/slices/laptopProduct.slice";

const INITIALVALUES = {
    ram: "",
    ram_bus: "",
    hdd_rpm: "",
    storage: "",
    ram_type: "",
    total_ram_slot: "",
    max_ram_support: "",
    storage_upgrade: "",
    installed_hdd_type: "",
    expansion_ram_slot: "",
    expansion_ssd_slot: "",
    installed_ssd_type: "",
    installed_ram_details: ""
};

const LaptopMemoryStorageForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createLaptopMemoryStorage] = useCreateLaptopMemoryStorageMutation();
    const [updateLaptopMemoryStorage] = useUpdateLaptopMemoryStorageMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateLaptopMemoryStorage({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(2);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createLaptopMemoryStorage({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(2);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <InputField 
                                name="ram"
                                label="Input laptop RAM size"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="ram_type"
                                label="Input laptop RAM type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="expansion_ram_slot"
                                label="Input laptop expansion RAM slot"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="max_ram_support"
                                label="Input laptop max RAM support"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="ram_bus"
                                label="Input laptop RAM bus speed"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="total_ram_slot"
                                label="Input laptop total RAM slot "
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="installed_ram_details"
                                label="Input laptop installed RAM details"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="storage"
                                label="Input laptop storage size"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField  
                                name="installed_hdd_type"
                                label="Input laptop installed HDD type"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="installed_ssd_type"
                                label="Input laptop installed SSD type"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField  
                                name="expansion_ssd_slot"
                                label="Input laptop expansion SSD slot"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField  
                                name="hdd_rpm"
                                label="Input laptop HDD RPM"
                            />
                        </Col>

                        <Col xs={24} lg={12}>
                            <InputField  
                                name="storage_upgrade"
                                label="Input laptop storage upgrade"
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(0)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default LaptopMemoryStorageForm;