import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const categoryApi = createApi({
    reducerPath: "categoryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/category`,
        credentials: "include",
        tagTypes: ["categories", "category"],
    }),
    endpoints: (builder) => ({
        getAllCategoryList: builder.query({
            query: (page = 1) => `?page=${page}`,
            providesTags: ["categories"],
        }),
        getCategoryByID: builder.query({
            query: (id) => `/${id}`,
            providesTags: ["category", "categories"],
        }),
        createCategory: builder.mutation({
            query: (data) => ({
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["categories"],
        }),
        updateCategoryById: builder.mutation({
            query: ({ id, data }) => ({
                url: `/${id}`,
                body: data,
                method: "PUT",
            }),
            invalidatesTags: ["category", "categories"],
        }),
    }),
});

export const {
    useGetCategoryByIDQuery,
    useCreateCategoryMutation,
    useGetAllCategoryListQuery,
    useUpdateCategoryByIdMutation,
} = categoryApi;
