import styled from "styled-components";

const StyleFAQList = styled.div`
    gap: 12px;
    display: flex;
    flex-direction: column;
`;

const StyleFaq = styled.div`
    gap: 12px;
    display: flex;
    padding: 12px;
    background: #fff;
    border-radius: 5px;
    justify-content: space-between;

    h4{
        color: #374151;
        font-size: 16px;
        font-weight: 500;
    }

    p{
        color: #374151;
        font-size: 14px;
    }
`;

const StyleSvgDiv = styled.div`
    gap: 8px;
    display: flex;
    font-size: 20px;

    .edit{
        color: #27348b;
    }

    .delete{
        color: red;
    }
`;

const StyleAddBtnSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
    button{
        color: #ffff;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 25px;
        border-radius: 5px;
        background: #27348b;
    }
`;

export {
    StyleFaq,
    StyleSvgDiv,
    StyleFAQList,
    StyleAddBtnSection
}