import styled from "styled-components";

const StyleImageSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    background: #fff;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #374151;

    svg{
        color: #27348b;
        font-size: 80px;
    }

    span{
        color: #27348b;
        font-weight: 500;
        font-size: 16px;
    }
`;

export {
    StyleImageSection,
}