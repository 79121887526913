import { Icon } from "@iconify/react";
import { useState, useRef, useEffect } from "react";
import {
    StyleDiv,
    StyleLabel,
    StyleOption,
    StyleSelectDiv,
} from "./styles/CategorySelect.style";

const CategorySelect = ({ category, setCategory, categoryList }) => {
    const ref = useRef();
    const [openList, setOpenList] = useState(false);

    const handleSelect = (item) => {
        setOpenList(false);
        setCategory(item.name);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpenList(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <StyleSelectDiv>
            <StyleLabel>Select Category</StyleLabel>

            <StyleDiv ref={ref} open={openList}>
                <button type="button" onClick={() => setOpenList(true)}>
                    {category ? category : "Select Category"}

                    <Icon
                        icon="iconamoon:arrow-up-2-light"
                        rotate={3}
                        vFlip={true}
                    />
                </button>

                {openList && categoryList?.length > 0 && (
                    <StyleOption>
                        {categoryList?.map((item) => (
                            <li
                                key={item.id}
                                onClick={() => handleSelect(item)}
                                className={
                                    category === item.name ? "active" : " "
                                }
                            >
                                {item.name}
                            </li>
                        ))}
                    </StyleOption>
                )}
            </StyleDiv>
        </StyleSelectDiv>
    );
};

export default CategorySelect;
