import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import InputField from "../inputs/InputField";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateLaptopProcessorMutation,
    useUpdateLaptopProcessorMutation
} from "../../views/product/slices/laptopProduct.slice";

const INITIALVALUES = {
    cpu_cache: "",
    generation: "",
    processor_core: "",
    processor_type: "",
    processor_model: "",
    processor_brand: "",
    processor_thread: "",
    processor_base_frequency: ""
};

const LaptopProcessorForm = ({
    update,
    handleBack,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createLaptopProcessor] = useCreateLaptopProcessorMutation();
    const [updateLaptopProcessor] = useUpdateLaptopProcessorMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateLaptopProcessor({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(1);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createLaptopProcessor({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(1);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <InputField 
                                name="processor_brand"
                                label="Input processor brand name"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="processor_model"
                                label="Input processor model"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="processor_base_frequency"
                                label="Input processor frequency"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="processor_core"
                                label="Input processor core"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="processor_thread"
                                label="Input processor thread"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="generation"
                                label="Input processor generation"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="processor_type"
                                label="Input processor type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <InputField 
                                name="cpu_cache"
                                label="Input CPU Cache"
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={handleBack}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default LaptopProcessorForm;