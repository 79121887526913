import { useRef } from "react";
import { useField } from "formik";
import { Icon } from "@iconify/react";
import {
    StyleImageSection,
} from "./styles/ProductImageUpload.style";

const ProductImageUpload = ({
    handleChange,
    setFieldValue,
    ...props
}) => {
    const fileRef = useRef();
    const [field, meta] = useField(props);

    const handleInput = () => {
        console.log("click", field.value)

        
        if(field.value?.length < 4) {
            return fileRef.current.click();
        }
    }

    return (
        <>
            <input 
                hidden
                type="file" 
                ref={fileRef} 
                accept="image/*"
                name={field.name}
                onChange={handleChange}
            />

            <StyleImageSection
                onClick={handleInput}
            >
                <Icon icon="ion:image-outline" />
                <span>Upload Image</span>
            </StyleImageSection>

            {(meta?.error && meta?.touched ) ? <p>{meta.error}</p> : null}
        </>
    );
}
 
export default ProductImageUpload;