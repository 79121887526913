import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateDroneAdditionalInfoMutation, 
    useUpdateDroneAdditionalInfoMutation
} from "../../views/product/slices/droneProduct.slice";

const INITIALVALUES = {
    weight: "",
    others: "",
    made_in: "",
    warranty: "",
    assemble: "",
    specialty: "",
    vision_system: "",
    operating_range: "",
    number_of_rotors: "",
    overall_dimensions: "",
    operating_environment: ""
};

const DroneAdditionalInfoForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createDroneAdditionalInfo] = useCreateDroneAdditionalInfoMutation();
    const [updateDroneAdditionalInfo] = useUpdateDroneAdditionalInfoMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateDroneAdditionalInfo({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(5);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createDroneAdditionalInfo({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(5);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="weight"
                                label="Input drone weight"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="others"
                                label="Input drone others information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="made_in"
                                label="Input drone made in"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="warranty"
                                label="Input drone warranty"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="assemble"
                                label="Input drone assemble"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="specialty"
                                label="Input drone specialty"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="vision_system"
                                label="Input drone vision system"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="operating_range"
                                label="Input drone operating range"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="number_of_rotors"
                                label="Input drone number of rotors"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="overall_dimensions"
                                label="Input drone overall dimensions"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="operating_environment"
                                label="Input drone operating environment"
                            />
                        </Col> 

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(3)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default DroneAdditionalInfoForm;