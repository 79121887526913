import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Table from "../../../components/table/Table";
import { 
    StyleSlugTd,
    StyleActionTd,
    StyleStatusTd,
    StyleActionDiv,
    StyleCategoryTd,
    StyleBlogImageTd,
    StyleContentSection 
} from "./styles/BlogTable.style";

const BlogTable = ({
    items
}) => {
    const columns = [
        {
            label: "Image",
            path: "banner_image",
            content: (item, path) => (
                <StyleBlogImageTd>
                    <img
                        alt={item.title}
                        src={item[path]}
                    />
                </StyleBlogImageTd>
            ),
        },
        {
            label: "Slug",
            path: "slug",
            sort: true,
            content: (item, path) => (
                <StyleSlugTd>
                    <div>
                        {item[path]}
                    </div>
                </StyleSlugTd>
            )
        },
        {
            label: "Title",
            path: "title",
            sort: true,
            content: (item, path) => (
                <StyleSlugTd>
                    <div>
                        {item[path]}
                    </div>
                </StyleSlugTd>
            )
        },
        {
            label: "Status",
            path: "status",
            sort: true,
            content: (item, path) => (
                <StyleStatusTd>
                    {item[path]}
                </StyleStatusTd>
            )
        },
        {
            label: "Category",
            path: "category",
            sort: true,
            content: (item, path) => (
                <StyleCategoryTd>
                    {item[path]}
                </StyleCategoryTd>
            )
        },
        {
            label: "Action",
            sort: true,
            content: (item) => (
                <StyleActionTd>
                    <StyleActionDiv>
                        <Link to={`/blog/update-blog/${item.id}`}>
                            <Icon icon="tabler:edit" />
                        </Link>

                        <button type="button">
                            {item.status === "DISABLE" && <Icon icon="fluent-mdl2:disable-updates" />}
                            {item.status === "ACTIVE" && <Icon icon="fontisto:checkbox-active" />}
                        </button>

                        <a href="www.google.com" target="_blank">
                            <Icon icon="carbon:view-filled" />
                        </a>
                    </StyleActionDiv>
                </StyleActionTd>
            )
        }
    ];

    return (
        <StyleContentSection>
            <Table 
                items={items}
                columns={columns}
            />
        </StyleContentSection>
    );
}
 
export default BlogTable;