import styled from "styled-components";
import { Link } from "react-router-dom";

const StyleOverViewSection = styled.div`
    padding-top: 30px;
`;

const StyleContentSection = styled.div`
    height: 100%;
    padding: 20px 15px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, .3);
`;

const StyleHeader = styled.div`
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
`;

const StyleHeading = styled.h4`
    font-size: 20px;
    font-weight: 500;
`;

const StyleLink = styled(Link)`
    color: #fff;
    background: red;
    font-size: 16px;
    font-weight: 500;
    padding: 4px 10px;
    border-radius: 5px;

    &:hover{
        color: #fff;
    }
`;

export {
    StyleLink,
    StyleHeader,
    StyleHeading,
    StyleContentSection,
    StyleOverViewSection
}