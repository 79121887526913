import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const droneProductApi = createApi({
    reducerPath: "droneProductApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}api/v1/secured/product/drone`,
        credentials: "include",
    }),
    tagTypes: ["drone"],
    endpoints: (builder) => ({
        getDroneById: builder.query({
            query: (id) => `/${id}`,
            providesTags: ["drone"],
        }),
        createDroneCamera: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/drone-camera",
            }),
            invalidatesTags: ["drone"],
        }),
        updateDroneCamera: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/drone-camera/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["drone"],
        }),
        createDroneRemote: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/drone-remote",
            }),
            invalidatesTags: ["drone"],
        }),
        updateDroneRemote: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/drone-remote/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["drone"],
        }),
        createDronePerformance: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/performance",
            }),
            invalidatesTags: ["drone"],
        }),
        updateDronePerformance: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/performance/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["drone"],
        }),
        createDroneAdditionalInfo: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/additional-info",
            }),
            invalidatesTags: ["drone"],
        }),
        updateDroneAdditionalInfo: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/additional-info/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["drone"],
        }),
        createDroneGimbalFlightBatteryCharger: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/gimbal-fligh-battery-charger",
            }),
            invalidatesTags: ["drone"],
        }),
        updateDroneGimbalFlightBatteryCharger: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/gimbal-fligh-battery-charger/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["drone"],
        }),
    }),
});

export const {
    useGetDroneByIdQuery,
    useCreateDroneRemoteMutation,
    useUpdateDroneRemoteMutation,
    useCreateDroneCameraMutation,
    useUpdateDroneCameraMutation,
    useCreateDronePerformanceMutation,
    useUpdateDronePerformanceMutation,
    useCreateDroneAdditionalInfoMutation,
    useUpdateDroneAdditionalInfoMutation,
    useCreateDroneGimbalFlightBatteryChargerMutation,
    useUpdateDroneGimbalFlightBatteryChargerMutation,
} = droneProductApi;
