import { StyleActionDiv, StyleActionTd } from "./styles/ActionLayout.style";

const ActionLayout = ({ children }) => {
    return (
        <StyleActionTd>
            <StyleActionDiv>{children}</StyleActionDiv>
        </StyleActionTd>
    );
};

export default ActionLayout;
