import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout";
import CategoryForm from "../components/CategoryForm";
import MainSection from "../../../components/section/MainSection";
import { useCreateCategoryMutation } from "../slices/category.slice";

const CreateCategory = () => {
    const navigate = useNavigate();
    const [createCategory, { isLoading, isError, error, status }] =
        useCreateCategoryMutation();

    const handleSubmit = (action, data) => createCategory(data);

    // const handleSubmit = (action, data) => console.log(data);

    if (isError) {
        toast.error(error.data);
    }

    if (status === "fulfilled") {
        toast.success("Successfully category created.");
        navigate("/category");
    }

    return (
        <Layout>
            <MainSection title="Create Category">
                <CategoryForm handleSubmit={handleSubmit} />
            </MainSection>
        </Layout>
    );
};

export default CreateCategory;
