import { toast } from "react-toastify";
import Layout from "../../../components/layout";
import CategoryForm from "../components/CategoryForm";
import { useNavigate, useParams } from "react-router-dom";
import MainSection from "../../../components/section/MainSection";
import {
    useGetCategoryByIDQuery,
    useUpdateCategoryByIdMutation,
} from "../slices/category.slice";

const UpdateCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, error, isLoading } = useGetCategoryByIDQuery(id);
    const [updateCategoryById, update] = useUpdateCategoryByIdMutation();

    const handleUpdateCategory = async (action, payload) =>
        await updateCategoryById({ id, data: payload });

    if (update.isError && update.status === "rejected") {
        toast.error(update.error.data);
    }

    if (update?.status === "fulfilled") {
        toast.success("Successfully category updated.");
        navigate("/category");
    }

    console.log(data);

    return (
        <Layout>
            <MainSection title="Update Category">
                {!error && !isLoading && (
                    <CategoryForm
                        update={true}
                        initialValues={{
                            ...data,
                            more_info: data?.more_info
                                ? JSON.parse(data?.more_info)
                                : [],
                            category_faq: data?.category_faq
                                ? JSON.parse(data?.category_faq)
                                : [],
                        }}
                        handleSubmit={handleUpdateCategory}
                    />
                )}
            </MainSection>
        </Layout>
    );
};

export default UpdateCategory;
