import {
    StyleDiv,
    StylePagination
} from "./styles/GlobalPagination.style";

const GlobalPagination = ({
    total,
    pageSize,
    activePage,
    handlePagination
}) => {
    return (
        <StyleDiv>
            <StylePagination 
                total={total}
                responsive={true}
                current={activePage}
                showSizeChanger={false}
                defaultPageSize={pageSize} 
                onChange={handlePagination} 
                defaultCurrent={activePage}
            />
        </StyleDiv>
    );
}
 
export default GlobalPagination;