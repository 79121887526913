import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout";
import { useQuery } from "../../../utilities/useQuery";
import EnlistStep from "../../../components/steps/EnlistStep";
import ProductFeatureForm from "../components/ProductFeatureForm";
import MainSection from "../../../components/section/MainSection";
import { useGetProductByIdQuery } from "../slices/product.slice";
import ProductMoreInfoForm from "../../../components/enlistForm/ProductMoreInfo";
import MobileProcessorMemoryForm from "../../../components/enlistForm/MobileProcessor&Memory";
import MobileDisplayPhysicalForm from "../../../components/enlistForm/MobileDisplay&Physical";
import MobileNetworkConnectivityForm from "../../../components/enlistForm/MobileNetwort&Connectivy";

const MobileEnlist = () => {
    const query = useQuery();
    const id = query.get("id");
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const { isLoading, data } = useGetProductByIdQuery(id);

    const STEPS = [
        {
            title: (
                <span onClick={() => activeStep > 0 && setActiveStep(0)}>
                    Camera & Sound
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 1 && setActiveStep(1)}>
                    Processor & Memory
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 2 && setActiveStep(2)}>
                    Display & physical
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 3 && setActiveStep(3)}>
                    Network & connectivity
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 4 && setActiveStep(4)}>
                    More Info
                </span>
            ),
        },
    ];

    console.log(data);

    return (
        <Layout>
            <section>
                <MainSection title="Mobile Enlist">
                    {!isLoading && data && (
                        <>
                            <EnlistStep
                                items={STEPS}
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                            />

                            {activeStep === 0 && (
                                <ProductFeatureForm
                                    update={data?.product_more_info}
                                    handleBack={() => setActiveStep(3)}
                                    initialValues={
                                        data?.product_features
                                            ? {
                                                  feature: JSON.parse(
                                                      data?.product_features
                                                          ?.feature,
                                                  ),
                                                  main_feature: JSON.parse(
                                                      data?.product_features
                                                          ?.main_feature,
                                                  ),
                                              }
                                            : null
                                    }
                                />
                            )}

                            {activeStep === 1 && (
                                <MobileProcessorMemoryForm
                                    setActiveStep={setActiveStep}
                                    update={data?.mobile_processor_memory}
                                    initialValues={
                                        data?.mobile_processor_memory
                                    }
                                />
                            )}

                            {activeStep === 2 && (
                                <MobileDisplayPhysicalForm
                                    setActiveStep={setActiveStep}
                                    update={data?.mobile_display_physical}
                                    initialValues={
                                        data?.mobile_display_physical
                                    }
                                />
                            )}

                            {activeStep === 3 && (
                                <MobileNetworkConnectivityForm
                                    setActiveStep={setActiveStep}
                                    update={data?.mobile_network_connectivity}
                                    initialValues={
                                        data?.mobile_network_connectivity
                                    }
                                />
                            )}

                            {activeStep === 4 && (
                                <ProductMoreInfoForm
                                    update={data?.product_more_info}
                                    handleBack={() => setActiveStep(3)}
                                    initialValues={data?.product_more_info}
                                />
                            )}
                        </>
                    )}
                </MainSection>
            </section>
        </Layout>
    );
};

export default MobileEnlist;
