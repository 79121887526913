import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const cameraProductApi = createApi({
    reducerPath: "cameraProductApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/product/camera`,
        credentials: "include",
    }),
    tagTypes: ["camera"],
    endpoints: (builder) => ({
        getCameraById: builder.query({
            query: (id) => `/${id}`,
            providesTags: ["camera"],
        }),
        createCameraGeneral: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/general",
            }),
            invalidatesTags: ["camera"],
        }),
        updateCameraGeneral: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/general/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["camera"],
        }),
        createCameraMainFeature: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/main-feature",
            }),
            invalidatesTags: ["camera"],
        }),
        updateCameraMainFeature: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/main-feature/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["camera"],
        }),
        createCameraTechnicalInfo: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/technical-info",
            }),
            invalidatesTags: ["camera"],
        }),
        updateCameraTechnicalInfo: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/technical-info/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["camera"],
        }),
        createCameraAdditionalInfo: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/additional-info",
            }),
            invalidatesTags: ["camera"],
        }),
        updateCameraAdditionalInfo: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/additional-info/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["camera"],
        }),
        createCameraPerformanceFlash: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/performance-flash",
            }),
            invalidatesTags: ["camera"],
        }),
        updateCameraPerformanceFlash: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/performance-flash/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["camera"],
        }),
        createCameraImageVideoDisplay: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/image-video-display",
            }),
            invalidatesTags: ["camera"],
        }),
        updateCameraImageVideoDisplay: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/image-video-display/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["camera"],
        }),
    }),
});

export const {
    useGetCameraByIdQuery,
    useCreateCameraGeneralMutation,
    useUpdateCameraGeneralMutation,
    useCreateCameraMainFeatureMutation,
    useUpdateCameraMainFeatureMutation,
    useCreateCameraTechnicalInfoMutation,
    useUpdateCameraTechnicalInfoMutation,
    useCreateCameraAdditionalInfoMutation,
    useUpdateCameraAdditionalInfoMutation,
    useCreateCameraPerformanceFlashMutation,
    useUpdateCameraPerformanceFlashMutation,
    useCreateCameraImageVideoDisplayMutation,
    useUpdateCameraImageVideoDisplayMutation,
} = cameraProductApi;
