import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateCameraAdditionalInfoMutation,
    useUpdateCameraAdditionalInfoMutation
} from "../../views/product/slices/cameraProduct.slice";

const INITIALVALUES = {
    gps: "",
    wifi: "",
    lens: "",
    others: "",
    battery: "",
    hdmi_port: "",
    bluetooth: "",
    specialty: "",
    connectivity: "",
    battery_life: "",
    usb_interface: ""
};

const CameraAdditionalInfoForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createCameraAdditionalInfo] = useCreateCameraAdditionalInfoMutation();
    const [updateCameraAdditionalInfo] = useUpdateCameraAdditionalInfoMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateCameraAdditionalInfo({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(5);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createCameraAdditionalInfo({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(5);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="gps"
                                label="Input camera GPS"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="wifi"
                                label="Input camera wifi"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="lens"
                                label="Input camera lens"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="others"
                                label="Input camera others information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery"
                                label="Input camera battery"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="hdmi_port"
                                label="Input camera HDMI port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="bluetooth"
                                label="Input camera bluetooth"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="specialty"
                                label="Input camera specialty"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="connectivity"
                                label="Input camera connectivity"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery_life"
                                label="Input camera battery life"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="usb_interface"
                                label="Input camera USB interface"
                            />
                        </Col> 

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(3)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default CameraAdditionalInfoForm;