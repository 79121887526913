import Layout from "../../../components/layout";
import CountryTable from "../components/CountryTable";
import { useNavigate, useLocation } from "react-router-dom";
import CreateLink from "../../../components/link/CreateLink";
import MainSection from "../../../components/section/MainSection";
import { useGetAllCountryListQuery } from "../slices/country.slice";
import GlobalPagination from "../../../components/pagination/GlobalPagination";

const CountryList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");

    const { data, isError, isLoading } = useGetAllCountryListQuery(
        page ? page : 1,
    );

    const handlePagination = (pageValue) => {
        navigate(`/country?page=${pageValue}`);
    };

    return (
        <Layout>
            <MainSection title="All Country List">
                <CreateLink link="/country/create" title="Create Country" />

                {data?.country && !isError && !isLoading && (
                    <>
                        <CountryTable items={data?.country} />

                        {data?.pageAble?.total > data?.pageAble?.limit && (
                            <GlobalPagination
                                total={data?.pageAble?.total}
                                pageSize={data?.pageAble?.limit}
                                handlePagination={handlePagination}
                                activePage={data?.pageAble?.activePage}
                            />
                        )}
                    </>
                )}
            </MainSection>
        </Layout>
    );
};

export default CountryList;
