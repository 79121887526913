import styled from "styled-components";

const StyleActionTd = styled.td`
    width: 85px;
    text-align: center;
`;

const StyleActionDiv = styled.div`
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: red;
    }

    button {
        color: red;
        border: none;
        outline: none;
        cursor: pointer;
        background: transparent;
    }
`;

export { StyleActionTd, StyleActionDiv };
