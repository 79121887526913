import Login from "../views/login";
import Dashboard from "../views/dashbord";
import { Routes, Route } from "react-router-dom";
import PublicRoute from "../components/routes/PublicRoute";
import PrivateRoute from "../components/routes/PrivateRoute";
import { BlogList, CreateBlog, UpdateBlog } from "../views/blog";
import {
    ProductList,
    DroneEnlist,
    MobileEnlist,
    CameraEnlist,
    LaptopEnlist,
    UpdateProduct,
    CreateProduct,
} from "../views/product";
import { UserList } from "../views/user";
import ServiceList from "../views/service";
import { ProfileList } from "../views/profile";
import { PermissionList } from "../views/permission";
import { CategoryList } from "../views/category";
import { ContactList } from "../views/contact";
import UpdateCategory from "../views/category/pages/UpdateCategory";
import CreateCategory from "../views/category/pages/CreateCategory";
import { BrandList, CreateBrand, UpdateBrand } from "../views/brand";
import { CountryList, CreateCountry, UpdateCountry } from "../views/country";

const App = () => {
    return (
        <>
            <Routes>
                <Route element={<PublicRoute />}>
                    <Route path="/login" element={<Login />} />
                </Route>

                <Route element={<PrivateRoute />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/user" element={<UserList />} />
                    <Route path="/user/create" element={<Dashboard />} />
                    <Route path="/profile" element={<ProfileList />} />
                    <Route path="/profile/create" element={<Dashboard />} />
                    <Route path="/permission" element={<PermissionList />} />
                    <Route path="/permission/create" element={<Dashboard />} />
                    <Route path="/service" element={<ServiceList />} />
                    <Route path="/blog" element={<BlogList />} />
                    <Route path="/blog" element={<BlogList />} />
                    <Route path="/product" element={<ProductList />} />
                    <Route
                        path="/product/mobile-enlist"
                        element={<MobileEnlist />}
                    />
                    <Route
                        path="/product/laptop-enlist"
                        element={<LaptopEnlist />}
                    />
                    <Route
                        path="/product/camera-enlist"
                        element={<CameraEnlist />}
                    />
                    <Route
                        path="/product/drone-enlist"
                        element={<DroneEnlist />}
                    />
                    <Route
                        path="/product/create-product"
                        element={<CreateProduct />}
                    />
                    <Route
                        path="/product/update-product/:id"
                        element={<UpdateProduct />}
                    />
                    <Route path="/country" element={<CountryList />} />
                    <Route path="/country/create" element={<CreateCountry />} />
                    <Route
                        path="/country/update/:id"
                        element={<UpdateCountry />}
                    />
                    <Route path="/category" element={<CategoryList />} />
                    <Route
                        path="/category/create"
                        element={<CreateCategory />}
                    />
                    <Route
                        path="/category/update/:id"
                        element={<UpdateCategory />}
                    />
                    <Route path="/brand" element={<BrandList />} />
                    <Route path="/brand/create" element={<CreateBrand />} />
                    <Route path="/brand/update/:id" element={<UpdateBrand />} />
                    <Route path="/contact" element={<ContactList />} />
                    <Route path="/blog/create-blog" element={<CreateBlog />} />
                    <Route
                        path="/blog/update-blog/:id"
                        element={<UpdateBlog />}
                    />
                </Route>
            </Routes>
        </>
    );
};

export default App;
