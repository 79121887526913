import styled from "styled-components";

const StyleDiv = styled.div`
    gap: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const StyleGoForwardBtn = styled.button`
    color: #ffff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 50px;
    border-radius: 5px;
    background: #27348b;
`;

const StyleBackBtn = styled(StyleGoForwardBtn)`
    color: #000;
    background: #f7f7f7;
`;

export {
    StyleDiv,
    StyleBackBtn,
    StyleGoForwardBtn
};