import { Navigate, Outlet } from "react-router-dom";
import { useGetProfileQuery } from "../../views/login/slices/login.slice";

const PrivateRoute = () => {
    const { isLoading, isSuccess } = useGetProfileQuery();

    return (
        <>
            {(isLoading && !isSuccess) ? (
                <h1>Loading</h1>
            ) : (
                isSuccess ? <Outlet /> : <Navigate to="/login" />
            )}
        </>
    );
}
 
export default PrivateRoute;