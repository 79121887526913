import styled from "styled-components";

const StyleContentSection = styled.div`
    min-height: 80%;
    padding: 20px 15px;
    margin-right: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, .3);
`;

const StyleBlogImageTd = styled.td`
    width: 55px;
    text-align: center;

    img{
        width: 50px;
        height: 65px;
        object-fit: cover;
        margin-bottom: -7px;
    }
`;

const StyleSlugTd = styled.td`
    width: calc(50% - 150px);

    div{
        width: 100%;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const StyleStatusTd = styled.td`
    width: 60px;
    text-align: center;

    div{
        width: 100%;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const StyleCategoryTd = styled.td`
    width: 100px;
    text-align: center;

    div{
        width: 100%;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const StyleActionTd = styled.td`
    width: 85px;
    text-align: center;
`;

const StyleActionDiv = styled.div`
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    a{
        color: red;
    }

    button{
        color: red;
        border: none;
        outline: none;
        cursor: pointer;
        background: transparent;
    }
`;

export {
    StyleSlugTd,
    StyleActionTd,
    StyleStatusTd,
    StyleActionDiv,
    StyleCategoryTd,
    StyleBlogImageTd,
    StyleContentSection
}