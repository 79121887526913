import Layout from "../../../components/layout";
import ContactTable from "../components/ContactTable";
import { useLocation, useNavigate } from "react-router-dom";
import MainSection from "../../../components/section/MainSection";
import { useGetAllContactListQuery } from "../slices/contact.slice";
import GlobalPagination from "../../../components/pagination/GlobalPagination";

const ContactList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");

    const { data, isError, isLoading } = useGetAllContactListQuery(
        page ? page : 1,
    );

    const handlePagination = (pageValue) => {
        navigate(`/contact?page=${pageValue}`);
    };

    console.log(data);

    return (
        <Layout>
            <MainSection title="All Contact List">
                {data?.contact && !isError && !isLoading && (
                    <>
                        <ContactTable items={data?.contact} />

                        {data?.pageAble?.total > data?.pageAble?.limit && (
                            <GlobalPagination
                                total={data?.pageAble?.total}
                                pageSize={data?.pageAble?.limit}
                                handlePagination={handlePagination}
                                activePage={data?.pageAble?.activePage}
                            />
                        )}
                    </>
                )}
            </MainSection>
        </Layout>
    );
};

export default ContactList;
