import { Steps } from "antd";
import styled from "styled-components";

const StyleEnlistStep = styled(Steps)`
    margin: 20px auto 36px auto;
    width: 90%;
    overflow: scroll;

    .ant-steps-item{
        .ant-steps-item-tail {
            margin-inline-start: 61px;

            &::after {
                height: 2px;
                background-color: #9CA3AF !important;
            }
        }

        .ant-steps-icon {
            font-size: 16px;
            font-weight: 600;
        }

        .ant-steps-item-title{
            font-size: 16px;
            font-weight: 600;
            color: #9CA3AF !important;
            cursor: pointer;
        }
    }

    .ant-steps-item-tail {
        margin-inline-start: 55px;
        padding: 8px 17px !important;
    }

    .ant-steps-item-icon {
        width: 34px !important;
        height: 34px !important;
        font-size: 15px !important;
        margin-inline-start: 38px;

        .ant-steps-icon{
            top: 3px;
            left: 1px;
        }
    }

    .ant-steps-item-finish{
        .ant-steps-item-tail {
            &::after {
                height: 2px;
                background-color: #27348B !important;
            }
        }

        .ant-steps-item-icon {
            background: #27348B !important;
            border: 1px solid #27348B;
            svg {
                color: #fff;
            }
        }

        .ant-steps-item-title{
            color: #27348B !important;
        }
    }

    .ant-steps-item-active{
        .ant-steps-item-icon {
            background: transparent !important;
            border: 2px solid #27348B;

            .ant-steps-icon{
                color: #27348B;
            }
        }

        .ant-steps-item-title{
            color: #27348B !important;
        }
    }

    .ant-steps-item-wait {
        .ant-steps-item-icon {
            background: transparent !important;
            border: 2px solid #9CA3AF;

            .ant-steps-icon {
                color: #9CA3AF !important;
            }
        }
    }

    .ant-steps-item-content{
        width: 120px !important;
    }

    &::-webkit-scrollbar{
        display: none !important;
    }

    @media (max-width: 761px) {
        width: 100%;
    }
`;

export default StyleEnlistStep;