import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout";
import { useQuery } from "../../../utilities/useQuery";
import EnlistStep from "../../../components/steps/EnlistStep";
import MainSection from "../../../components/section/MainSection";
import { useGetDroneByIdQuery } from "../slices/droneProduct.slice";
import DroneCamera from "../../../components/enlistForm/DroneCamera";
import DroneRemoteForm from "../../../components/enlistForm/DroneRemote";
import ProductMoreInfoForm from "../../../components/enlistForm/ProductMoreInfo";
import DronePerformanceForm from "../../../components/enlistForm/DronePerformance";
import DroneAdditionalInfoForm from "../../../components/enlistForm/DroneAdditionalInfo";
import DroneGimbalFlightBatteryForm from "../../../components/enlistForm/DroneGImbalFlight&Battery";

const DroneEnlist = () => {
    const query = useQuery();
    const id = query.get("id");
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const { isLoading,  data} = useGetDroneByIdQuery(id);

    const STEPS = [
        {
            title: <span onClick={() => activeStep > 0 && setActiveStep(0)}>Camera</span>,
        },
        {
            title: <span onClick={() => activeStep > 1 && setActiveStep(1)}>Performance</span>,
        },
        {
            title: <span onClick={() => activeStep > 2 && setActiveStep(2)}>Remote</span>,
        },
        {
            title: <span onClick={() => activeStep > 3 && setActiveStep(3)}>Gimbal, Fligh & Battery</span>,
        },
        {
            title: <span onClick={() => activeStep > 4 && setActiveStep(4)}>Additional Info</span>,
        },
        {
            title: <span onClick={() => activeStep > 5 && setActiveStep(5)}>More Info</span>,
        }
    ];
    
    console.log(data);

    return (
        <Layout>
            <section>
                <MainSection
                    title="Drone Enlist"
                >
                    {!isLoading && data && (
                        <>
                            <EnlistStep 
                                items={STEPS}
                                activeStep={activeStep} 
                                setActiveStep={setActiveStep}
                            />

                            {activeStep === 0 && (
                                <DroneCamera 
                                    setActiveStep={setActiveStep}
                                    update={data?.drone_camera}
                                    initialValues={data?.drone_camera}
                                    handleBack={() => navigate(`/product/update-product/${id}`)}
                                />
                            )}

                            {activeStep === 1 && (
                                <DronePerformanceForm 
                                    setActiveStep={setActiveStep}
                                    update={data?.drone_performance}
                                    initialValues={data?.drone_performance}
                                />
                            )}

                            {activeStep === 2 && (
                                <DroneRemoteForm 
                                    setActiveStep={setActiveStep}
                                    update={data?.drone_remote}
                                    initialValues={data?.drone_remote}
                                />
                            )}

                            {activeStep === 3 && (
                                <DroneGimbalFlightBatteryForm 
                                    setActiveStep={setActiveStep}
                                    update={data?.drone_gimbal_filght_battery_charger}
                                    initialValues={data?.drone_gimbal_filght_battery_charger}
                                />
                            )}

                            {activeStep === 4 && (
                                <DroneAdditionalInfoForm 
                                    setActiveStep={setActiveStep}
                                    update={data?.drone_additional_info}
                                    initialValues={data?.drone_additional_info}
                                />
                            )}

                            {activeStep === 5 && (
                                <ProductMoreInfoForm 
                                    update={data?.product_more_info}
                                    handleBack={() => setActiveStep(4)}
                                    initialValues={data?.product_more_info}
                                />
                            )}
                        </>
                    )}
                </MainSection>
            </section>
        </Layout>
    );
}
 
export default DroneEnlist;