import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/users`,
        credentials: "include",
        tagTypes: ["users", "user"],
    }),
    endpoints: (builder) => ({
        getAllUserList: builder.query({
            query: (page = 1) => `?page=${page}`,
            invalidatesTags: ["user"],
        }),
    }),
});

export const { useGetAllUserListQuery } = userApi;
