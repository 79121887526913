import { 
    StyleLabel,
    StyleTextArea 
} from "./styles/TextArea.style";

const FaqTextArea = ({
    name,
    label,
    error,
    value,
    onChange,
    className,
    placeholder,
    ...props
}) => {
    return (
        <div>
            {label && (
                <StyleLabel htmlFor={name}>
                    {label}
                </StyleLabel> 
            )}

            <StyleTextArea 
                rows={4}
                {...props}
                name={name}
                value={value}
                onChange={onChange}
                className={className}
                placeholder={placeholder}
            />

            {error && <p>{error[name]}</p>}
        </div>
    );
}
 
export default FaqTextArea;