import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateDroneCameraMutation, 
    useUpdateDroneCameraMutation
} from "../../views/product/slices/droneProduct.slice";

const INITIALVALUES = {
    sensor_type: "",
    max_aperture: "",
    video_format: "",
    field_of_view: "",
    shutter_speed: "",
    iso_sensitivity: "",
    memory_card_slot: "",
    sensor_resolution: "",
    min_focus_distance: "",
    still_image_support: ""
};

const DroneCamera = ({
    update,
    handleBack,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createDroneCamera] = useCreateDroneCameraMutation();
    const [updateDroneCamera] = useUpdateDroneCameraMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateDroneCamera({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(1);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createDroneCamera({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(1);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="sensor_type"
                                label="Input drone sensor type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_aperture"
                                label="Input drone max aperture"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="video_format"
                                label="Input drone video format"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="field_of_view"
                                label="Input drone field view"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="shutter_speed"
                                label="Input drone shutter speed"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="iso_sensitivity"
                                label="Input drone ISO sensitivity"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="memory_card_slot"
                                label="Input drone memory card slot"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="sensor_resolution"
                                label="Input drone sensor resolution"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="min_focus_distance"
                                label="Input drone min focus distance"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="still_image_support"
                                label="Input drone still image support"
                            />
                        </Col> 

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={handleBack}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default DroneCamera;