import Layout from "../../../components/layout";
import CreateLink from "../../../components/link/CreateLink";
import MainSection from "../../../components/section/MainSection";
import ProfileTable from "../components/ProfileTable";

const ProfileList = () => {
    return (
        <Layout>
            <MainSection title="All Profile List">
                <CreateLink link="/" title="Create Profile" />

                <ProfileTable />
            </MainSection>
        </Layout>
    );
};

export default ProfileList;
