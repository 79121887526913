import { Link } from "react-router-dom";
import Table from "./Table";

const ProductTable = () => {
    const columns = [
        {
            label: "Image",
            path: "posterUrl",
            content: (movie, path) => (
                <td style={{ width: "50%" }}>
                    <img
                        src={movie[path]}
                        alt={movie.id}
                        style={{ width: "45px", height: "auto" }}
                    />
                </td>
            ),
        },
        {
            label: "Title",
            path: "id",
            sort: true,
            content: (movie) => <td>{movie.id}</td>,
        },
        {
            label: "Type",
            path: "id",
            sort: true,
            content: (movie) => <td>{movie.id}</td>,
        },
        {
            label: "Status",
            path: "title",
            sort: true,
            content: (movie) => (
                <td>
                    {movie.title}
                </td>
            ),
        },
        {
            label: "Action",
            path: "runtime",
            sort: true,
            content: (movie) => <td>{movie.runtime} min</td>,
        },
    ];

    return (
        <Table 
            columns={columns}
        />
    );
}
 
export default ProductTable;