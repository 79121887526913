import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const countryApi = createApi({
    reducerPath: "countryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/country`,
        credentials: "include",
        tagTypes: ["countries", "country"],
    }),
    endpoints: (builder) => ({
        getAllCountryList: builder.query({
            query: (page = 1) => `?page=${page}`,
            providesTags: ["countries"],
        }),
        getCountryByID: builder.query({
            query: (id) => `/${id}`,
            providesTags: ["country", "countries"],
        }),
        createCountry: builder.mutation({
            query: (data) => ({
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["countries"],
        }),
        updateCountryById: builder.mutation({
            query: ({ id, data }) => ({
                url: `/${id}`,
                body: data,
                method: "PUT",
            }),
            invalidatesTags: ["country", "countries"],
        }),
    }),
});

export const {
    useGetCountryByIDQuery,
    useCreateCountryMutation,
    useGetAllCountryListQuery,
    useUpdateCountryByIdMutation,
} = countryApi;
