import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyleNav = styled.nav`
    width: 300px;
    height: 100%;
    position: fixed;
    overflow: hidden;
`;

const StyleUl = styled.ul`
    width: 100%;
    height: 100%;
    padding: 10px 0;
    position: relative;
    background: #5d6491;
`;

const StyleLi = styled.li`
    width: 100%;
    list-style: none;
    position: relative;

    &:hover {
        background-color: rgba(153, 92, 153, 0.7);
    }
`;

const StyleLink = styled(NavLink)`
    color: #fff;
    width: 100%;
    display: block;
    display: flex;
    padding-left: 8px;
    position: relative;
    align-items: center;

    svg {
        display: block;
        font-size: 36px;
        position: relative;
        text-align: center;
        margin-right: 8px;
    }

    .icon-title {
        height: 50px;
        display: block;
        font-size: 18px;
        line-height: 50px;
        position: relative;
    }
`;

const StyleLogoutBtn = styled.button`
    color: #fff;
    width: 100%;
    border: none;
    display: flex;
    cursor: pointer;
    padding-left: 8px;
    position: relative;
    align-items: center;
    background: transparent;

    svg {
        display: block;
        font-size: 36px;
        position: relative;
        text-align: center;
        margin-right: 8px;
    }

    .icon-title {
        height: 50px;
        display: block;
        font-size: 18px;
        line-height: 50px;
        position: relative;
    }
`;

export { StyleLi, StyleUl, StyleNav, StyleLink, StyleLogoutBtn };
