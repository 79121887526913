import { toast } from "react-toastify";
import BlogForm from "../components/BlogForm";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout";
import MainSection from "../../../components/section/MainSection";
import { useCreateBlogMutation,  } from "../slices/getBlogList.slice";

const CreateBlog = () => {
    const navigate = useNavigate();
    const [createBlog, { isLoading, isError, error, status }] = useCreateBlogMutation();

    const handleSubmit = (action, data) => createBlog(data);

    if(isError) {
        toast.error(error.data);
    };

    if(status === "fulfilled") {
        toast.success("Successfuly blog created.");
        navigate("/blog");
    };

    return (
        <Layout>
            <MainSection
                title="Create Blog"
            >
                <BlogForm 
                    handleSubmit={handleSubmit}
                />
            </MainSection>
        </Layout>
    );
}
 
export default CreateBlog;