import Layout from "../../../components/layout";
import CreateLink from "../../../components/link/CreateLink";
import MainSection from "../../../components/section/MainSection";
import PermissionTable from "../components/PermissionTable";

const PermissionList = () => {
    return (
        <Layout>
            <MainSection title="All Permission List">
                <CreateLink link="/" title="Create Permission" />

                <PermissionTable />
            </MainSection>
        </Layout>
    );
};

export default PermissionList;
