import { toast } from "react-toastify";
import BlogForm from "../components/BlogForm";
import { useNavigate, useParams } from "react-router-dom";
import MainSection from "../../../components/section/MainSection";
import { 
    useGetBlogByIdQuery, 
    useUpdateBlogByIdMutation 
} from "../slices/getBlogList.slice";
import Layout from "../../../components/layout";

const UpdateBlog = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, error, isLoading } = useGetBlogByIdQuery(id);
    const [updateBlogById, update] = useUpdateBlogByIdMutation();

    const handleUpdateBlog = async (action, payload) => await updateBlogById({ id, data: payload });

    if(update.isError && update.status === "rejected") {
        toast.error(update.error.data);
    };

    if(update?.status === "fulfilled") {
        toast.success("Successfuly blog updated.");
        navigate("/blog");
    };

    return (
        <Layout>
            <MainSection
                title="Update Blog"
            >
                {!error && !isLoading && (
                    <BlogForm 
                        update={true}
                        initialValues={data}
                        handleSubmit={handleUpdateBlog}
                    />
                )}
            </MainSection>
        </Layout>
    );
}
 
export default UpdateBlog;