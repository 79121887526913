import { Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/inputs/InputField";
import { useUserLoginMutation } from "./slices/login.slice";
import {
    StyleForm,
    StyleSection,
    StyleLoginButton
} from "./styles/Login.style";

const INITIALVALUES = {
    email: "",
    password: ""
};

const Login = () => {
    const navigate = useNavigate();
    const [userLogin, { isLoading }] = useUserLoginMutation();

    const handleLogin = async (values) => {
        const data = await userLogin(values);

        !data && toast.error("There was a problem try again.");
    
        if(data) {
            navigate("/");
        }
    }

    return (
        <StyleSection>
            <Formik
                onSubmit={handleLogin}
                initialValues={INITIALVALUES}
            >
                {({ handleSubmit }) => (
                    <StyleForm onSubmit={handleSubmit}>
                        <InputField 
                            name="email"
                            label="Enter your email"
                            placeholder="Please enter your email."
                        />

                        <InputField 
                            type="password"
                            name="password"
                            label="Enter your password"
                            placeholder="Please enter your password."
                        />

                        <StyleLoginButton type="submit" disabled={isLoading}>
                            Login
                        </StyleLoginButton>
                    </StyleForm>
                )}
            </Formik>
        </StyleSection>
    );
}
 
export default Login;