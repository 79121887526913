import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userApi } from "../views/login/slices/login.slice";
import { blogApi } from "../views/blog/slices/getBlogList.slice";
import { productApi } from "../views/product/slices/product.slice";
import { serviceApi } from "../views/service/slices/service.slice";
import { droneProductApi } from "../views/product/slices/droneProduct.slice";
import { mobileProductApi } from "../views/product/slices/mobileProduct.slice";
import { laptopProductApi } from "../views/product/slices/laptopProduct.slice";
import { cameraProductApi } from "../views/product/slices/cameraProduct.slice";
import { contactApi } from "../views/contact/slices/contact.slice";
import { brandApi } from "../views/brand/slices/brand.slice";
import { categoryApi } from "../views/category/slices/category.slice";
import { countryApi } from "../views/country/slices/country.slice";
import { permissionApi } from "../views/permission/slices/permission.slice";
import { profileApi } from "../views/profile/slices/profile.slice";

const store = configureStore({
    reducer: {
        [blogApi.reducerPath]: blogApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [productApi.reducerPath]: productApi.reducer,
        [mobileProductApi.reducerPath]: mobileProductApi.reducer,
        [laptopProductApi.reducerPath]: laptopProductApi.reducer,
        [cameraProductApi.reducerPath]: cameraProductApi.reducer,
        [droneProductApi.reducerPath]: droneProductApi.reducer,
        [serviceApi.reducerPath]: serviceApi.reducer,
        [contactApi.reducerPath]: contactApi.reducer,
        [brandApi.reducerPath]: brandApi.reducer,
        [categoryApi.reducerPath]: categoryApi.reducer,
        [countryApi.reducerPath]: countryApi.reducer,
        [permissionApi.reducerPath]: permissionApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            blogApi.middleware,
            productApi.middleware,
            userApi.middleware,
            mobileProductApi.middleware,
            laptopProductApi.middleware,
            cameraProductApi.middleware,
            droneProductApi.middleware,
            serviceApi.middleware,
            contactApi.middleware,
            brandApi.middleware,
            categoryApi.middleware,
            countryApi.middleware,
            profileApi.middleware,
        ),
});

setupListeners(store.dispatch);

export default store;
