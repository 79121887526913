import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout";
import { useQuery } from "../../../utilities/useQuery";
import EnlistStep from "../../../components/steps/EnlistStep";
import MainSection from "../../../components/section/MainSection";
import { useGetCameraByIdQuery } from "../slices/cameraProduct.slice";
import CameraGeneralForm from "../../../components/enlistForm/CameraGeneral";
import ProductMoreInfoForm from "../../../components/enlistForm/ProductMoreInfo";
import CameraMainFeatureForm from "../../../components/enlistForm/CameraMainFeature";
import CameraTechnicalInfoForm from "../../../components/enlistForm/CameraTechnicalInfo";
import CameraAdditionalInfoForm from "../../../components/enlistForm/CameraAdditionalInfo";
import CameraPerformanceFlashForm from "../../../components/enlistForm/CameraPerformance&Flash";
import CameraImageVideoDisplayForm from "../../../components/enlistForm/CameraImageVideoDisplay";

const CameraEnlist = () => {
    const query = useQuery();
    const id = query.get("id");
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const { isLoading, data } = useGetCameraByIdQuery(id);

    const STEPS = [
        {
            title: (
                <span onClick={() => activeStep > 0 && setActiveStep(0)}>
                    Technical Info
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 1 && setActiveStep(1)}>
                    Performance & Flash
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 2 && setActiveStep(2)}>
                    Image, Video & Display
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 3 && setActiveStep(3)}>
                    Main Feature
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 4 && setActiveStep(4)}>
                    Additional Info{" "}
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 5 && setActiveStep(5)}>
                    General
                </span>
            ),
        },
        {
            title: (
                <span onClick={() => activeStep > 6 && setActiveStep(6)}>
                    More Info
                </span>
            ),
        },
    ];

    return (
        <Layout>
            <section>
                <MainSection title="Camera Enlist">
                    {!isLoading && data && (
                        <>
                            <EnlistStep
                                items={STEPS}
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                            />

                            {activeStep === 0 && (
                                <CameraTechnicalInfoForm
                                    setActiveStep={setActiveStep}
                                    update={data?.camera_technical_info}
                                    initialValues={data?.camera_technical_info}
                                    handleBack={() =>
                                        navigate(
                                            `/product/update-product/${id}`,
                                        )
                                    }
                                />
                            )}

                            {activeStep === 1 && (
                                <CameraPerformanceFlashForm
                                    setActiveStep={setActiveStep}
                                    update={data?.camera_performance_flash}
                                    initialValues={
                                        data?.camera_performance_flash
                                    }
                                />
                            )}

                            {activeStep === 2 && (
                                <CameraImageVideoDisplayForm
                                    setActiveStep={setActiveStep}
                                    update={data?.camera_image_video_display}
                                    initialValues={
                                        data?.camera_image_video_display
                                    }
                                />
                            )}

                            {activeStep === 3 && (
                                <CameraMainFeatureForm
                                    setActiveStep={setActiveStep}
                                    update={data?.camera_main_feature}
                                    initialValues={data?.camera_main_feature}
                                />
                            )}

                            {activeStep === 4 && (
                                <CameraAdditionalInfoForm
                                    setActiveStep={setActiveStep}
                                    update={data?.camera_additional_info}
                                    initialValues={data?.camera_additional_info}
                                />
                            )}

                            {activeStep === 5 && (
                                <CameraGeneralForm
                                    setActiveStep={setActiveStep}
                                    update={data?.camera_general}
                                    initialValues={data?.camera_general}
                                />
                            )}

                            {activeStep === 6 && (
                                <ProductMoreInfoForm
                                    update={data?.product_more_info}
                                    handleBack={() => setActiveStep(5)}
                                    initialValues={data?.product_more_info}
                                />
                            )}
                        </>
                    )}
                </MainSection>
            </section>
        </Layout>
    );
};

export default CameraEnlist;
