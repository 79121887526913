import styled from "styled-components";

const StyleDiv = styled.div`
    gap: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const StyleAddBtn = styled.button`
    color: #ffff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 10px;
    border-radius: 5px;
    background: #5d6491;
`;

export { StyleDiv, StyleAddBtn };
