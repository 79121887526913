import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import CountrySchema from "../schema/country.schema";
import AddButton from "../../../components/button/AddButton";
import InputField from "../../../components/inputs/InputField";
import MaxPriceCard from "../../../components/card/MaxPriceCard";
import SubmitButton from "../../../components/button/SubmitButton";
import CategorySelect from "../../../components/select/CategorySelect";
import { useGetAllCategoryListQuery } from "../../category/slices/category.slice";
import CountryMaxPriceInput from "../../../components/inputs/CountryMaxPriceInput";

const INITIALVALUES = {
    name: "",
    title: "",
    currency: "",
    category: null,
};

const CountryForm = ({ update, handleSubmit, initialValues }) => {
    const [toggle, setToggle] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [addedCategory, setAddedCategory] = useState(
        initialValues?.category ? initialValues?.category : null,
    );
    const [editCategory, setEditCategory] = useState(null);
    const [categoryInfo, setCategoryInfo] = useState({
        name: "",
        max_price: "",
    });
    const { data, isError, isLoading } = useGetAllCategoryListQuery();

    const handleChange = (name, value) => {
        setCategoryInfo({ ...categoryInfo, [name]: value });
    };

    const handleEditCateInfo = (item) => {
        setEditCategory(item);
        setCategoryInfo({ name: item?.name, max_price: item?.max_price });
    };

    const handleDeleteCateInfo = (deleteID, dataList, dataSetFun) => {
        const filterData = dataList?.filter((data) => data?.id !== deleteID);

        dataSetFun("category", filterData);
    };

    const handleAddCategoryInfo = (setFieldValue, value) => {
        if (categoryInfo.name && categoryInfo.max_price) {
            const data = value ? value : [];

            if (editCategory) {
                const newData = data?.map((item) => {
                    if (item?.id === editCategory?.id) {
                        const editItem = {
                            ...item,
                            name: categoryInfo?.name,
                            max_price: categoryInfo?.max_price,
                        };

                        return editItem;
                    }

                    return item;
                });

                setCategoryInfo({
                    name: "",
                    max_price: "",
                });

                setEditCategory(null);
                setAddedCategory(newData);
                setToggle((prev) => !prev);

                return setFieldValue("category", newData);
            }

            const makePayload = {
                ...categoryInfo,
                id: new Date().getMilliseconds(),
            };

            data.push(makePayload);

            setCategoryInfo({
                name: "",
                max_price: "",
            });

            setAddedCategory(data);
            setToggle((prev) => !prev);

            setFieldValue("category", data);
        }
    };

    const handleFilterCategory = (addData) => {
        const filterData = data?.category?.filter(
            (obj1) => !addData.some((obj2) => obj1.name === obj2.name),
        );

        setCategoryList(filterData);
    };

    useEffect(() => {
        if (data?.category) {
            if (addedCategory) {
                return handleFilterCategory(addedCategory);
            }

            setCategoryList(data?.category);
        }
    }, [toggle, data]);

    return (
        <Formik
            validationSchema={CountrySchema}
            initialValues={initialValues ? initialValues : INITIALVALUES}
            onSubmit={(values, action) =>
                handleSubmit(action, {
                    ...values,
                })
            }
        >
            {({ handleSubmit, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <InputField
                                name="name"
                                label="Write country name"
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <InputField
                                name="currency"
                                label="Write country currency"
                            />
                        </Col>
                        <Col xs={24}>
                            <InputField
                                name="title"
                                label="Write country title"
                            />
                        </Col>
                        {values?.category && values?.category?.length > 0 && (
                            <Col xs={24}>
                                <MaxPriceCard
                                    edit={editCategory}
                                    data={values?.category}
                                    handleEdit={handleEditCateInfo}
                                    handleDelete={(id) =>
                                        handleDeleteCateInfo(
                                            id,
                                            values.category,
                                            setFieldValue,
                                        )
                                    }
                                />
                            </Col>
                        )}

                        {(categoryList?.length > 0 || editCategory) && (
                            <Col xs={24}>
                                <Row gutter={[24, 12]}>
                                    <Col xs={24} lg={12}>
                                        <CategorySelect
                                            categoryList={categoryList}
                                            category={categoryInfo.name}
                                            setCategory={(value) =>
                                                handleChange("name", value)
                                            }
                                        />
                                    </Col>

                                    <Col xs={24} lg={12}>
                                        <CountryMaxPriceInput
                                            type="number"
                                            name="max_price"
                                            label="Add category max price"
                                            value={categoryInfo.max_price}
                                            onChange={(e) =>
                                                handleChange(
                                                    e.target.name,
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </Col>

                                    <Col xs={24}>
                                        <AddButton
                                            buttonTitle={
                                                editCategory
                                                    ? "Update Category"
                                                    : "Add Category"
                                            }
                                            onClick={() =>
                                                handleAddCategoryInfo(
                                                    setFieldValue,
                                                    values.category,
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        )}

                        <Col xs={24}>
                            <SubmitButton
                                title={update ? "Update" : "Submit"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default CountryForm;
