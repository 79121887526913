import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateCameraGeneralMutation,
    useUpdateCameraGeneralMutation
} from "../../views/product/slices/cameraProduct.slice";

const INITIALVALUES = {
    type: "",
    made_in: "",
    country: "",
    category: "",
    warranty: "",
};

const CameraGeneralForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createCameraGeneral] = useCreateCameraGeneralMutation();
    const [updateCameraGeneral] = useUpdateCameraGeneralMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateCameraGeneral({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(6);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createCameraGeneral({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(6);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="type"
                                label="Input camera type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="made_in"
                                label="Input made in"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="country"
                                label="Input camera country"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="category"
                                label="Input camera category"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="warranty"
                                label="Input camera warranty"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="hdmi_port"
                                label="Input camera HDMI port"
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(4)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default CameraGeneralForm;