import styled from "styled-components";

const StyleDiv = styled.div`
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`;

const StyleCard = styled.div`
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    text-align: center;

    h4 {
        color: #374151;
        font-weight: 500;
        font-size: 14px;
    }

    p {
        color: #5d6491;
        font-weight: 500;
    }
`;

const StyleButtonSection = styled.div`
    gap: 8px;
    display: flex;
    font-size: 18px;
    margin-top: 8px;
    align-items: center;
    justify-content: end;

    .edit {
        color: #374151;
        cursor: pointer;
    }

    .delete {
        color: #5d6491;
        cursor: pointer;
    }
`;

export { StyleDiv, StyleCard, StyleButtonSection };
