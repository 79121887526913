import styled from "styled-components";

const StyleDiv = styled.div`
    gap: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const StyleImageSection = styled.div`
    gap: 12px;
    height: 100% ;
    display: flex;
    padding-top: 8px;
    align-items: center;
`;

const StyleImageDiv = styled.div`
    height: 100%;
    width: calc(25% - 6px);
`;

export {
    StyleDiv,
    StyleImageDiv,
    StyleImageSection
};