import styled from "styled-components";

const StyleAddSection = styled.div`
    gap: 8px;
    display: flex;
    margin-top: 8px;
    align-items: center;
`;

const StyleLabel = styled.label`
    color: #374151;
    font-size: 16px;
    font-weight: 500;
`;

const StyleInput = styled.input`
    border: none;
    color: #374151;
    font-size: 14px;
    font-weight: 400;
    padding: 9px 12px;
    border-radius: 5px;
    background: #ffffff;
    outline: 1px solid #E5E7EB;
    font-family: 'Poppins', sans-serif;
    
    &&::placeholder{
        color: #9CA3AF;
    }
`;

const StyleAddBtn = styled.button`
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 11px 15px;
    border-radius: 5px;
    background: #27348b;
`;

const StyleAddPriceList = styled.div`
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
`;

const StylePrice = styled.div`
    gap: 8px;
    padding: 4px;
    display: flex;
    background: #fff;
    align-items: center;

    button{
        padding: 0;
        border: none;
        outline: none;
        display: flex;
        cursor: pointer;
        align-items: center;
        background: #ebf0f4;
    }

    p{
        text-transform: capitalize;
    }
`;

export {
    StyleLabel,
    StyleInput,
    StylePrice,
    StyleAddBtn,
    StyleAddSection,
    StyleAddPriceList
};