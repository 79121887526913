import { toast } from "react-toastify";
import Layout from "../../../components/layout";
import CountryForm from "../components/CountryForm";
import { useNavigate, useParams } from "react-router-dom";
import MainSection from "../../../components/section/MainSection";
import {
    useGetCountryByIDQuery,
    useUpdateCountryByIdMutation,
} from "../slices/country.slice";

const UpdateCountry = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, error, isLoading } = useGetCountryByIDQuery(id);
    const [updateCountryById, update] = useUpdateCountryByIdMutation();

    const handleUpdateCountry = async (action, payload) =>
        await updateCountryById({ id, data: payload });

    if (update.isError && update.status === "rejected") {
        toast.error(update.error.data);
    }

    if (update?.status === "fulfilled") {
        toast.success("Successfully country updated.");
        navigate("/country");
    }

    return (
        <Layout>
            <MainSection title="Update Country">
                {!error && !isLoading && (
                    <CountryForm
                        update={true}
                        initialValues={{
                            ...data,
                            category: data?.category
                                ? JSON.parse(data?.category)
                                : [],
                        }}
                        handleSubmit={handleUpdateCountry}
                    />
                )}
            </MainSection>
        </Layout>
    );
};

export default UpdateCountry;
