import { Col, Row } from "antd";
import { useState } from "react";
import { Icon } from "@iconify/react";
import FaqTextArea from "../inputs/FaqTextArea";
import {
    StyleFaq,
    StyleSvgDiv,
    StyleFAQList,
    StyleAddBtnSection,
} from "./styles/ProductFaq.style";

const ProductFeature = ({ main_feature, setFieldValue }) => {
    const [edit, setEdit] = useState(false);
    const [feature, setFeature] = useState({ title: "", value: "" });

    const handleChange = (event) => {
        const data = {
            ...feature,
            [event.target.name]: event.target.value,
        };

        setFeature(data);
    };

    const handleDelete = (id) => {
        const filterData = main_feature.filter((item) => item.id !== id);

        setFieldValue("main_feature", filterData);
    };

    const handleEdit = (id) => {
        const [filterData] = main_feature.filter((item) => item.id === id);

        setEdit(true);
        setFeature(filterData);
    };

    const handleAddFeature = () => {
        const payload = {
            ...feature,
            id: feature?.id ? feature?.id : new Date().getMilliseconds(),
        };

        if (edit) {
            const faqList = main_feature;

            const editAble = faqList.find((item) => item.id == feature.id);

            editAble.title = payload?.title;
            editAble.value = payload.description;

            setEdit(false);
            setFeature({ title: "", value: "" });

            return setFieldValue("main_feature", faqList);
        }

        setFieldValue("main_feature", [payload, ...main_feature]);

        setFeature({ title: "", value: "" });
    };

    return (
        <>
            {main_feature?.length > 0 && (
                <Col xs={24}>
                    <StyleFAQList>
                        {main_feature.map((item) => (
                            <StyleFaq
                                key={item.id}
                                style={{
                                    display: `${
                                        edit && item?.id === feature?.id
                                            ? "none"
                                            : "flex"
                                    }`,
                                }}
                            >
                                <div>
                                    <h4>{item.title}</h4>

                                    <p>{item.description}</p>
                                </div>

                                <StyleSvgDiv>
                                    <Icon
                                        className="edit"
                                        icon="material-symbols:edit"
                                        onClick={() => handleEdit(item.id)}
                                    />
                                    <Icon
                                        className="delete"
                                        icon="ic:baseline-delete"
                                        onClick={() => handleDelete(item.id)}
                                    />
                                </StyleSvgDiv>
                            </StyleFaq>
                        ))}
                    </StyleFAQList>
                </Col>
            )}

            <Col xs={24}>
                <Row gutter={[24, 12]}>
                    <Col xs={24} lg={12}>
                        <FaqTextArea
                            error={null}
                            name="title"
                            value={feature?.title}
                            onChange={handleChange}
                            label="Add main feature key"
                        />
                    </Col>

                    <Col xs={24} lg={12}>
                        <FaqTextArea
                            error={null}
                            name="description"
                            onChange={handleChange}
                            value={feature?.description}
                            label="Add main feature value"
                        />
                    </Col>

                    <Col xs={24}>
                        <StyleAddBtnSection>
                            <button type="button" onClick={handleAddFeature}>
                                {edit
                                    ? "Edit Main Feature"
                                    : "Add Main Feature"}
                            </button>
                        </StyleAddBtnSection>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default ProductFeature;
