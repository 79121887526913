import { toast } from "react-toastify";
import Layout from "../../../components/layout";
import BrandForm from "../components/BrandForm";
import { useNavigate, useParams } from "react-router-dom";
import MainSection from "../../../components/section/MainSection";
import {
    useGetBrandByIDQuery,
    useUpdateBrandByIdMutation,
} from "../slices/brand.slice";

const UpdateBrand = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, error, isLoading } = useGetBrandByIDQuery(id);
    const [updateBrandById, update] = useUpdateBrandByIdMutation();

    const handleUpdateBrand = async (action, payload) =>
        await updateBrandById({ id, data: payload });

    if (update.isError && update.status === "rejected") {
        toast.error(update.error.data);
    }

    if (update?.status === "fulfilled") {
        toast.success("Successfully brand updated.");
        navigate("/brand");
    }

    console.log(data);

    return (
        <Layout>
            <MainSection title="Update Brand">
                {!error && !isLoading && (
                    <BrandForm
                        update={true}
                        initialValues={{
                            ...data,
                            more_info: data?.more_info
                                ? JSON.parse(data?.more_info)
                                : [],
                            brand_faq: data?.brand_faq
                                ? JSON.parse(data?.brand_faq)
                                : [],
                        }}
                        handleSubmit={handleUpdateBrand}
                    />
                )}
            </MainSection>
        </Layout>
    );
};

export default UpdateBrand;
