import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateMobileNetworkConnectivityMutation,
    useUpdateMobileNetworkConnectivityMutation
} from "../../views/product/slices/mobileProduct.slice";

const INITIALVALUES = {
    sim: "",
    gps: "",
    otg: "",
    usb: "",
    wifi: "",
    sensors: "",
    network: "",
    ip_rating: "",
    bluetooth: "",
    fingerprint: "",
    battery_type: "",
    battery_charging: ""
};

const MobileNetworkConnectivityForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createMobileNetworkConnectivity] = useCreateMobileNetworkConnectivityMutation();
    const [updateMobileNetworkConnectivity] = useUpdateMobileNetworkConnectivityMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateMobileNetworkConnectivity({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(4);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createMobileNetworkConnectivity({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(4);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="sim"
                                label="Input mobile SIM support"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="network"
                                label="Input mobile network support"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="wifi"
                                label="Input mobile wi-fi information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="bluetooth"
                                label="Input mobile bluttooth information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="gps"
                                label="Input mobile GPS information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="usb"
                                label="Input mobile USB information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="otg"
                                label="Input mobile OTG information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery_type"
                                label="Input mobile battery type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery_charging"
                                label="Input mobile battery charging information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="fingerprint"
                                label="Input mobile fingerprint information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="sensors"
                                label="Input mobile sensors information"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="ip_rating"
                                label="Input mobile IP rating information"
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(4)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default MobileNetworkConnectivityForm;