import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const blogApi = createApi({
    reducerPath: "blogApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/blogs`,
        credentials: "include",
    }),
    tagTypes: ["blogs", "blog"],
    endpoints: (builder) => ({
        getAllBlogList: builder.query({
            query: (page = 1) => `?page=${page}`,
            providesTags: ["blogs"],
        }),
        getBlogById: builder.query({
            query: (id) => `/${id}`,
            providesTags: ["blog"],
        }),
        createBlog: builder.mutation({
            query: (data) => ({
                url: "",
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["blogs"],
        }),
        updateBlogById: builder.mutation({
            query: ({ id, data }) => ({
                url: `/${id}`,
                body: data,
                method: "PUT",
            }),
            invalidatesTags: ["blogs", "blog"],
        }),
        uploadBlogImage: builder.mutation({
            query: (imageFile) => {
                const bodyFormData = new FormData();
                bodyFormData.append("image", imageFile);

                return {
                    url: `/images`,
                    method: "POST",
                    body: bodyFormData,
                };
            },
        }),
    }),
});

export const {
    useGetAllBlogListQuery,
    useGetBlogByIdQuery,
    useCreateBlogMutation,
    useUpdateBlogByIdMutation,
    useUploadBlogImageMutation,
} = blogApi;
