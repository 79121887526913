import styled from "styled-components";

const StyleImageDiv = styled.div`
    height: 100%;
    position: relative;
    width: calc(25% - 6px);
    
    img{
        width: 100%;
        height: auto;
    }
`;

const StyleDeleteButton = styled.button`
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    border: none;
    outline: none;
    display: flex;
    cursor: pointer;
    position: absolute;
    align-items: center;
    background: transparent;
    justify-content: center;

    svg{
        display: none;
        font-size: 24px;
    }

    &:hover{ 
        color: red;

        svg{
            display: block;
        }
    }
`;

export {
    StyleImageDiv,
    StyleDeleteButton
};