import styled from "styled-components";

const StyleContentSection = styled.div`
    padding: 20px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0px -1px 15px -6px rgb(5 17 80 / 5%),
        0px 8px 15px -5px rgb(5 17 80 / 10%);
`;

const StyleHeadingSection = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
`;

const StyleHeading = styled.p`
    color: #374151;
    font-size: 24px;
    font-weight: 500;
`;

const StyleTable = styled.table`
    width: 100%;
    min-height: 300px;
    border: none;
    border: none;
    background: #fff;
    border-collapse: collapse;

    th,
    td {
        border: none;
    }

    thead {
        border-bottom: 2px solid black;

        th {
            padding: 5px 0;
        }
    }

    tbody {
        tr {
            td {
                padding: 8px 0;
            }

            &:nth-child(even) {
                background: #fff;
            }

            &:nth-child(odd) {
                background: #f6f6f5;
            }

            &:hover {
                cursor: pointer;
                /* background: rgba(0, 0, 0, 0.3); */
            }
        }
    }
`;

export { StyleTable, StyleHeading, StyleHeadingSection, StyleContentSection };
