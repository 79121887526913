import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateCameraTechnicalInfoMutation,
    useUpdateCameraTechnicalInfoMutation
} from "../../views/product/slices/cameraProduct.slice";

const INITIALVALUES = {
    speaker: "",
    processor: "",
    adio_port: "",
    lens_mount: "",
    microphone: "",
    sensor_size: "",
    mega_pixels: "",
    sensor_type: "",
    storage_type: ""
};

const CameraTechnicalInfoForm = ({
    update,
    handleBack,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createCameraTechnicalInfo] = useCreateCameraTechnicalInfoMutation();
    const [updateCameraTechnicalInfo] = useUpdateCameraTechnicalInfoMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateCameraTechnicalInfo({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(1);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createCameraTechnicalInfo({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(1);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="speaker"
                                label="Input camera speaker"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="processor"
                                label="Input camera processor"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="adio_port"
                                label="Input camera audio port"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="lens_mount"
                                label="Input camera lens mount"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="microphone"
                                label="Input camera microphone"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="sensor_size"
                                label="Input camera sensor size"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="mega_pixels"
                                label="Input camera mega pixels"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="sensor_type"
                                label="Input camera sensor type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="storage_type"
                                label="Input camera storage type"
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={handleBack}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default CameraTechnicalInfoForm;