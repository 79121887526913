import styled from "styled-components";

const StyleButtonDiv = styled.div`
    display: flex;
    align-items: center;
`;

const StyleSubmitButton = styled.button`
    width: 50%;
    color: #ffff;
    border: none;
    outline: none;
    margin: 0 auto;
    padding: 5px 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    background: #27348b;
`;

export {
    StyleButtonDiv,
    StyleSubmitButton
}