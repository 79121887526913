import { Collapse } from "antd";
import styled from "styled-components";

const StyleContentSection = styled.div`
    padding: 20px;
    /* margin-top: 40px; */
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0px -1px 15px -6px rgb(5 17 80 / 5%),
        0px 8px 15px -5px rgb(5 17 80 / 10%);
`;

const StyleHeadingSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyleHeading = styled.p`
    color: #374151;
    font-size: 20px;
    font-weight: 500;
`;

const StyleCollapse = styled(Collapse)`
    border-radius: 0;
    margin-top: 12px;
    font-family: "Poppins", sans-serif;

    .ant-collapse-item {
        background: #ebf0f4;

        .ant-collapse-header {
            color: #374151;
            font-size: 16px;
            font-weight: 500;

            .ant-collapse-arrow {
                font-size: 16px;
                transition: 0.3s ease-in-out;
            }
        }

        .ant-collapse-content-box {
            padding-top: 0;

            p {
                color: #374151;
            }
        }

        &.ant-collapse-item-active {
            .ant-collapse-header {
                .ant-collapse-arrow {
                    transition: 0.3s ease-in-out;
                    transform: rotate(180deg);
                }
            }
        }

        &:last-child {
            border-radius: 0;
        }
    }
`;

const StyleAddButton = styled.button`
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 5px;
    background: #5d6491;
`;

const StyleButtonSection = styled.div`
    gap: 8px;
    display: flex;
    font-size: 18px;
    margin-top: 8px;
    align-items: center;
    justify-content: end;

    .edit {
        color: #374151;
        cursor: pointer;
    }

    .delete {
        color: #5d6491;
        cursor: pointer;
    }
`;

// const StyleButtonSection = styled.div`
//     gap: 8px;
//     display: flex;
//     margin-top: 8px;
//     margin-bottom: 6px;
//     align-items: center;
//     justify-content: flex-end;
// `;

export {
    StyleHeading,
    StyleCollapse,
    StyleAddButton,
    StyleButtonSection,
    StyleHeadingSection,
    StyleContentSection,
};
