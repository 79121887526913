import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const serviceApi = createApi({
    reducerPath: "serviceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/services`,
        credentials: "include",
    }),
    endpoints: (builder) => ({
        getAllServiceList: builder.query({
            query: (page = 1) => `?page=${page}`,
        }),
    }),
});

export const { useGetAllServiceListQuery } = serviceApi;
