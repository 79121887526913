import styled from "styled-components";

const StyleLabel = styled.label`
    color: #374151;
    font-size: 16px;
    font-weight: 500;
`;

const StyleInput = styled.input`
    width: 100%;
    border: none;
    color: #374151;
    font-size: 14px;
    margin-top: 8px;
    font-weight: 400;
    padding: 9px 12px;
    border-radius: 5px;
    background: #ffffff;
    outline: 1px solid #e5e7eb;
    font-family: "Poppins", sans-serif;

    &&::placeholder {
        color: #9ca3af;
    }
`;

const StyleError = styled.p`
    color: red;
    font-size: 12px;
    margin-top: 6px;
`;

export { StyleError, StyleLabel, StyleInput };
