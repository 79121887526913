import {
    StyleHeading,
    StyleMainSection
} from "./styles/MainSection.style";

const MainSection = ({
    title,
    children
}) => {
    return (
        <StyleMainSection>
            <StyleHeading>
                {title}
            </StyleHeading>

            {children}
        </StyleMainSection>
    );
}
 
export default MainSection;