import { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import FaqTextArea from "../inputs/FaqTextArea";

const AddFAQModal = ({
    open,
    formTitle,
    editValue,
    handleSubmit,
    handleCancel,
}) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const handleAddFaq = () => {
        if (title && description) {
            const payload = {
                title,
                description,
            };

            handleSubmit(payload);

            setTitle("");
            setDescription("");
        }
    };

    const handleClose = () => {
        setTitle("");
        setDescription("");

        handleCancel();
    };

    useEffect(() => {
        if (editValue) {
            setTitle(editValue?.title ? editValue?.title : "");
            setDescription(
                editValue?.description ? editValue?.description : "",
            );
        }
    }, [editValue]);

    return (
        <Modal
            open={open}
            title={formTitle}
            onCancel={handleClose}
            footer={[
                <Button key="back" onClick={handleClose}>
                    Close
                </Button>,

                <Button key="submit" type="primary" onClick={handleAddFaq}>
                    {editValue ? "Update" : "Submit"}
                </Button>,
            ]}
        >
            <FaqTextArea
                error={null}
                name="title"
                value={title}
                label="Input your title"
                onChange={(event) => setTitle(event.target.value)}
            />

            <FaqTextArea
                error={null}
                name="description"
                value={description}
                label="Input your description"
                onChange={(event) => setDescription(event.target.value)}
            />
        </Modal>
    );
};
export default AddFAQModal;
