import styled from "styled-components";

const StyleLabel = styled.label`
    color: #374151;
    font-size: 16px;
    font-weight: 500;
`;

const StyleImageSection = styled.div`
    width: 250px;
    height: 175px;
    display: flex;
    margin-top: 8px;
    cursor: pointer;
    background: #fff;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    align-items: center;
    border: 1px solid #000;
    justify-content: center;
`;

const StyleBannerImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const StyleDeleteButton = styled.button`
    width: 100%;
    height: 100%;
    color: #fff;
    border: none;
    outline: none;
    display: flex;
    cursor: pointer;
    position: absolute;
    align-items: center;
    background: transparent;
    justify-content: center;

    svg{
        font-size: 24px;
    }

    &:hover{
        color: red;
    }
`;

export {
    StyleLabel,
    StyleBannerImage,
    StyleImageSection,
    StyleDeleteButton
}