import {
    StyleDiv,
    StyleBackBtn,
    StyleGoForwardBtn
} from "./styles/BackAndGoForwad.style";

const BackAndGoForwad = ({
    title,
    handleBack
}) => {
    return (
        <StyleDiv>
            <StyleBackBtn 
                type="button"
                onClick={handleBack}
            >
                Back
            </StyleBackBtn>

            <StyleGoForwardBtn type="submit">
                {title}
            </StyleGoForwardBtn>
        </StyleDiv>
    );
}
 
export default BackAndGoForwad;