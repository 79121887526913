import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateDronePerformanceMutation, 
    useUpdateDronePerformanceMutation
} from "../../views/product/slices/droneProduct.slice";

const INITIALVALUES = {
    gnss_support: "",
    max_tilt_angle: "",
    max_hover_time: "",
    flight_ceiling: "",
    max_flight_time: "",
    max_ascent_speed: "",
    hovering_accuracy: "",
    max_descent_speed: "",
    max_takeoff_weight: "",
    max_wind_resistance: "",
    max_horizontal_speed: ""
};

const DronePerformanceForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createDronePerformance] = useCreateDronePerformanceMutation();
    const [updateDronePerformance] = useUpdateDronePerformanceMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateDronePerformance({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(2);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createDronePerformance({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(2);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="gnss_support"
                                label="Input drone gnss support"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_tilt_angle"
                                label="Input drone max tilt angle"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_hover_time"
                                label="Input drone max hover time"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="flight_ceiling"
                                label="Input drone flight ceiling"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_flight_time"
                                label="Input drone max flight time"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_ascent_speed"
                                label="Input drone max ascent speed"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="hovering_accuracy"
                                label="Input drone hovering accuracy"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_descent_speed"
                                label="Input drone max descent speed"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_takeoff_weight"
                                label="Input drone max takeoff weight"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_wind_resistance"
                                label="Input drone max wind resistance"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="max_horizontal_speed"
                                label="Input drone max horizontal speed"
                            />
                        </Col> 

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(0)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default DronePerformanceForm;