import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const mobileProductApi = createApi({
    reducerPath: "mobileProductApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/secured/product`,
        credentials: "include",
    }),
    tagTypes: ["mobile"],
    endpoints: (builder) => ({
        getMobileById: builder.query({
            query: (id) => `/${id}`,
            providesTags: ["mobile"],
        }),
        createMobileCameraSound: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/camera-sound",
            }),
            invalidatesTags: ["mobile"],
        }),
        updateMobileCameraSound: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/camera-sound/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["mobile"],
        }),
        createMobileProcessorMemory: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/processor-memory",
            }),
            invalidatesTags: ["mobile"],
        }),
        updateMobileProcessorMemory: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/processor-memory/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["mobile"],
        }),
        createMobileDisplayPhysical: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/display-physical",
            }),
            invalidatesTags: ["mobile"],
        }),
        updateMobileDisplayPhysical: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                method: "PUT",
                url: `/display-physical/${id}`,
            }),
            invalidatesTags: ["mobile"],
        }),
        createMobileDisplayPhysical: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/display-physical",
            }),
            invalidatesTags: ["mobile"],
        }),
        updateMobileDisplayPhysical: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                method: "PUT",
                url: `/display-physical/${id}`,
            }),
            invalidatesTags: ["mobile"],
        }),
        createMobileNetworkConnectivity: builder.mutation({
            query: ({ data }) => ({
                body: data,
                method: "POST",
                url: "/network-connectivity",
            }),
            invalidatesTags: ["mobile"],
        }),
        updateMobileNetworkConnectivity: builder.mutation({
            query: ({ data, id }) => ({
                body: data,
                url: `/network-connectivity/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["mobile"],
        }),
    }),
});

export const {
    useGetMobileByIdQuery,
    useCreateMobileCameraSoundMutation,
    useUpdateMobileCameraSoundMutation,
    useCreateMobileProcessorMemoryMutation,
    useUpdateMobileProcessorMemoryMutation,
    useCreateMobileDisplayPhysicalMutation,
    useUpdateMobileDisplayPhysicalMutation,
    useCreateMobileNetworkConnectivityMutation,
    useUpdateMobileNetworkConnectivityMutation,
} = mobileProductApi;
