import { StyleAddBtn, StyleDiv } from "./styles/AddButton.style";

const AddButton = ({ onClick, buttonTitle }) => {
    return (
        <StyleDiv>
            <StyleAddBtn type="button" onClick={onClick}>
                {buttonTitle}
            </StyleAddBtn>
        </StyleDiv>
    );
};

export default AddButton;
