import styled from "styled-components";

const StyleSelectDiv = styled.div`
    width: fit-content;
`;

const StyleLabel = styled.label`
    color: #374151;
    font-size: 16px;
    font-weight: 500;
`;

const StyleDiv = styled.div`
    width: 100%;
    margin-top: 8px;
    position: relative;
    
    button{
        width: 100%;
        height: 100%;
        border: none;
        display: flex;
        outline: none;
        color: #374151;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        padding: 9px 12px;
        border-radius: 5px;
        align-items: center;
        background: #ffffff;
        text-transform: capitalize;
        justify-content: space-between;

        svg{
            font-weight: 600;
            font-size: 20px;
            transition: 0.3s all;
            transform: ${({ open }) => open ? "rotate(90deg)" : "rotate(0deg)"};
        }
    }
`;

const StyleOption = styled.ul`
    gap: 4px;
    left: 0;
    right: 0;
    top: 42px;
    z-index: 9999;
    display: flex;
    background: #fff;
    padding: 9px 0;
    position: absolute;
    flex-direction: column; 
    box-shadow: -3px 2px 4px 0px rgba(0,0,0,0.61);

    li{
        color: #374151;
        font-size: 14px;
        cursor: pointer;
        padding: 4px 12px;
        font-weight: 500;
        text-transform: capitalize;

        &.active{
            background: #eaeeee;
        }

        &:hover{
            background: #eaeeee;
        }
    }
`;

export {
    StyleDiv, 
    StyleLabel,
    StyleOption,
    StyleSelectDiv
};