import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import AddFAQModal from "../Modal/AddFAQModal";
import {
    StyleHeading,
    StyleCollapse,
    StyleAddButton,
    StyleButtonSection,
    StyleHeadingSection,
    StyleContentSection,
} from "./styles/FAQSection.style";

const FAQSection = ({
    title,
    dataList,
    formTitle,
    buttonTitle,
    handleSetValue,
}) => {
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [editValue, setEditValue] = useState("");

    const handleEdit = (data) => {
        setEditValue(data);
        setOpen(true);
    };

    const handleDelete = (deleteId) => {
        const filterData = dataList?.filter((item) => item?.id !== deleteId);

        handleSetValue(filterData);
    };

    const showModal = () => {
        setOpen(true);
    };

    const handleSubmit = (payload) => {
        if (!editValue) {
            const dataFormat = {
                ...payload,
                id: new Date().getMilliseconds(),
            };
            const data = [...dataList];

            data.push(dataFormat);
            handleSetValue(data);

            return setOpen(false);
        }

        const prevData = [...dataList];

        const editAble = prevData.find((item) => item.id == editValue?.id);

        editAble.title = payload?.title;
        editAble.description = payload?.description;

        setEditValue("");
        handleSetValue(prevData);

        return setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
        setEditValue("");
    };

    useEffect(() => {
        const collapseItem = dataList?.map((data, index) => ({
            key: index + 1,
            label: data.title,
            children: (
                <>
                    <StyleButtonSection>
                        <Icon
                            className="edit"
                            icon="material-symbols:edit"
                            onClick={() => handleEdit(data)}
                        />
                        <Icon
                            className="delete"
                            icon="ic:baseline-delete"
                            onClick={() => handleDelete(data.id)}
                        />
                    </StyleButtonSection>
                    {data.description}
                </>
            ),
        }));

        setItems(collapseItem ? collapseItem : []);
    }, [dataList]);

    return (
        <StyleContentSection>
            <StyleHeadingSection>
                <StyleHeading>{title}</StyleHeading>

                <StyleAddButton type="button" onClick={showModal}>
                    {buttonTitle}
                </StyleAddButton>
            </StyleHeadingSection>

            {items.length > 0 && (
                <div>
                    <StyleCollapse
                        accordion
                        items={items}
                        expandIconPosition="end"
                        defaultActiveKey={["1"]}
                        expandIcon={() => <Icon icon="ep:arrow-up-bold" />}
                    />
                </div>
            )}

            {
                <AddFAQModal
                    open={open}
                    editValue={editValue}
                    formTitle={formTitle}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                />
            }
        </StyleContentSection>
    );
};

export default FAQSection;
