import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../inputs/TextArea";
import { useQuery } from "../../utilities/useQuery";
import BackAndGoForwad from "../button/BackAndGoForwad";
import { 
    useCreateDroneRemoteMutation, 
    useUpdateDroneRemoteMutation
} from "../../views/product/slices/droneProduct.slice";

const INITIALVALUES = {
    output_power: "",
    battery_type: "",
    battery_capacity: "",
    display_brightness: "",
    operating_distance: "",
    display_resolution: "",
    object_reflectivity: "",
    operating_frequency: "",
    battery_configuration: "",
    operating_temperature: ""
};

const DroneRemoteForm = ({
    update,
    setActiveStep,
    initialValues
}) => {
    const query = useQuery();
    const id = query.get("id");
    const [createDroneRemote] = useCreateDroneRemoteMutation();
    const [updateDroneRemote] = useUpdateDroneRemoteMutation();

    const handleSubmit = async (values, action) => {
        const payload = {
            ...values,
            product_id: id,
        };

        if(update && initialValues) {
            const { data, error } = await updateDroneRemote({ data: payload, id });

            if(error && !data) return toast.error("There was an error");

            if(data && !error) {
                setActiveStep(3);
                action.resetForm();

                return toast.success("Update successfully");
            }
        }

        const { data, error } = await createDroneRemote({ 
            data: payload
        });

        if(error && !data) return toast.error("There was an error");

        if(data && !error) {
            setActiveStep(3);
            action.resetForm();

            return toast.success("Create successfully");
        }
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues ? initialValues : INITIALVALUES}
        >
            {({ handleSubmit }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <Row gutter={[24, 12]}>
                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="output_power"
                                label="Input drone output power"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery_type"
                                label="Input drone remote battery type"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery_capacity"
                                label="Input drone remote battery capacity"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="display_brightness"
                                label="Input drone display brightness"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="operating_distance"
                                label="Input drone operating distance"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="display_resolution"
                                label="Input drone display resolution"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="object_reflectivity"
                                label="Input drone object reflectivity"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="operating_frequency"
                                label="Input drone operating frequency"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="battery_configuration"
                                label="Input drone remote battery configuration"
                            />
                        </Col> 

                        <Col xs={24} lg={12}>
                            <TextArea 
                                name="operating_temperature"
                                label="Input drone operating temperature"
                            />
                        </Col>

                        <Col xs={24}>
                            <BackAndGoForwad 
                                handleBack={() => setActiveStep(1)}
                                title={update ? "Update & Go Forward" : "Create & Go Forward"}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}
 
export default DroneRemoteForm;