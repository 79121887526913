import Table from "../../../components/table/Table";
import { StyleContentSection } from "./styles/ServiceTable.style";

const ServiceTable = ({ items }) => {
    const columns = [
        {
            label: "ID",
            path: "id",
            content: (item, path) => (
                <td style={{ width: "40%" }}>{item[path]}</td>
            ),
        },
        {
            label: "Title",
            path: "title",
            content: (item, path) => (
                <td style={{ width: "30%" }}>{item[path]}</td>
            ),
        },
        {
            label: "Slug",
            path: "slug",
            content: (item, path) => (
                <td style={{ width: "30%" }}>{item[path]}</td>
            ),
        },
        {
            label: "Action",
            content: () => <td style={{ width: "10%" }}>Action</td>,
        },
    ];

    return (
        <StyleContentSection>
            <Table columns={columns} items={items} />
        </StyleContentSection>
    );
};

export default ServiceTable;
