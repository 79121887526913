import styled from "styled-components";

const StyleDiv = styled.div`
    width: 100%;
    display: flex;
    background: #fff;
    min-height: 350px;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
`;

export { StyleDiv };
