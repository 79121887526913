import { Icon } from "@iconify/react";
import user from "../../assets/user.jpg";
import {
    StyleHeader,
    StyleUserImage,
    StyleHeaderLeft,
    StyleUserSection
} from "./styles/Header.style";

const Header = ({
    handleToggleMenu
}) => {
    return (
        <StyleHeader>
            <StyleHeaderLeft>
                <Icon icon="fa:navicon" onClick={handleToggleMenu}/>

                <h4>Dashboard</h4>
            </StyleHeaderLeft>

            <StyleUserSection>
                <StyleUserImage>
                    <img src={user} alt="user" />
                </StyleUserImage>

                <div className="user-name">
                    <h4>Amanullha Zoha</h4>
                    <span>Admin</span>
                </div>
            </StyleUserSection>
        </StyleHeader>
    );
}
 
export default Header;