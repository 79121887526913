import Layout from "../../components/layout";
import InfoCardSection from "./components/InfoCardSection";
import OverviewSection from "./components/OverviewSection";

const Dashboard = () => {
    return (
        <Layout>
            <InfoCardSection />
            <OverviewSection />
        </Layout>
    );
}
 
export default Dashboard;